import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import AuthActions, { getToken } from '@/auth/redux/reducer';
import checkToken from "@/helpers/checkToken";
import { store } from '@/store';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const token = useSelector(state => getToken(state));

  return (
    <Route
      {...rest}
      render={props => {
        const validateToken = checkToken(token)

        if (validateToken.error) {
          const { dispatch } = store;
  
          dispatch(AuthActions.logoutUser());
          return <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />;
        }

        return <Component {...props} />;
      }}
    />
)};

export default PrivateRoute;
