import { message } from 'antd';
import { put, all, takeLatest, select, call } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

import Actions, { Types } from './reducer';

export function dispatchClearMessages() {
  return dispatch => dispatch(Actions.clearMessages());
}

function* dispatchFetchChatRooms(action) {
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/chat_rooms`;
  try {
    const { data } = yield call(api.get, url, {
      params: { call_id: action.callId }
    });

    if (data.length > 0) {
      yield put(Actions.setRoomId(data[0].id));
      yield put(Actions.fetchMessages());
    }
    return yield put(Actions.fetchChatRoomsSuccess(data));
  } catch (error) {
    message.error('Erro ao puxa as salas de chat.');
    yield put(Actions.fetchChatRoomsError());
  }
}

function* dispatchFetchMessages(action) {
  const { roomId } = yield select(state => state.MaintenanceChatReducer);

  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/messages`;
  try {
    const { data } = yield call(api.get, url, {
      params: { chat_room_id: roomId, page: 1 }
    });

    return yield put(Actions.fetchMessagesSuccess(data));
  } catch (error) {
    message.error('Erro ao puxa as mensagens.');
    yield put(Actions.fetchMessagesError());
  }
}

function* dispatchCreateMessage(action) {
  const { roomId } = yield select(state => state.MaintenanceChatReducer);
  const content = action.contentMessage;

  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/messages`;
  try {
    const { data } = yield call(api.post, url, {
      chat_room_id: roomId,
      content
    });

    yield put(Actions.fetchMessages());
    // return yield call(Actions.createMessageSuccess(data));
  } catch (error) {
    message.error(
      'Erro ao enviar mensagem, verifique sua coneção com a internet.'
    );
    yield put(Actions.createMessageError());
  }
}

function dispatchTogglePolling() {
  return dispatch => dispatch(Actions.togglePolling());
}

function dispatchSetRoomId(roomId) {
  return dispatch => dispatch(Actions.setRoomId(roomId));
}

export default function*() {
  yield all([
    takeLatest(Types.FETCH_CHAT_ROOMS, dispatchFetchChatRooms),
    takeLatest(Types.CREATE_MESSAGE, dispatchCreateMessage),
    takeLatest(Types.TOGGLE_POLLING, dispatchTogglePolling),
    takeLatest(Types.SET_ROOM_ID, dispatchSetRoomId),
    takeLatest(Types.FETCH_MESSAGES, dispatchFetchMessages)
  ]);
}
