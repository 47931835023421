import { message } from 'antd';
import { put, all, takeLatest, select, call } from 'redux-saga/effects';

import listFilter, { getFilterOptions } from '@/helpers/listFilter';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

import mockConfigMaintenance from '../../../mocks/config_maintenance.json';
import Actions, { Types } from './reducer';


const url = process.env.REACT_APP_BASE_URL_V2

function* fetchItemsSagas({ endpoint }) {
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield all([
      put(Actions.fetchItemsSuccess(mockConfigMaintenance.damages)),
      call(filterItemsSagas)
    ])
  }
  try {
    const { data, status } = yield call(api.get, `${url}/workflow/${endpoint}/list_${endpoint}`);

    const convertedData = data.map(item => {
      return { ...item, enabled: item.enabled ? "ATIVADO" : "DESATIVADO" }
    })

    if (status === 200) {
      return yield all([
        put(Actions.fetchItemsSuccess(convertedData)),
        call(filterItemsSagas)
      ])
    }
    return yield put(Actions.fetchItemsError());
  } catch (error) {
    message.error("Erro na requisição dos dados, tente novamente", 3)
    yield put(Actions.fetchItemsError())
  }
}

function* fetchCategoriesSagas({ endpoint }) {
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(Actions.fetchCategoriesSuccess(mockConfigMaintenance.categories))
  }
  try {
    const { data, status } = yield call(api.get, `${url}/workflow/${endpoint}/list_categories`);

    if (status === 200) {
      return yield put(Actions.fetchCategoriesSuccess(data))
    }
    return yield put(Actions.fetchCategoriesError());
  } catch (error) {
    message.error("Erro na requisição das categorias, tente novamente", 3)
    yield put(Actions.fetchCategoriesError())
  }
}

function* fetchTypesDamagesSagas({ endpoint }) {
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(Actions.fetchTypesDamagesSuccess(mockConfigMaintenance.types))
  }
  try {
    const { data, status } = yield call(api.get, `${url}/workflow/${endpoint}/list_type_damages`);

    if (status === 200) {
      return yield put(Actions.fetchTypesDamagesSuccess(data))
    }
    return yield put(Actions.fetchTypesDamagesError());
  } catch (error) {
    message.error("Erro na requisição dos tipos de avaria, tente novamente", 3)
    yield put(Actions.fetchTypesDamagesError())
  }
}

function* filterItemsSagas() {
  const data = yield select(state => state.MaintenanceConfigurationReducer)
  const { items, filterValues, filterKeys } = data
  const indexedItems = listFilter({ content: items, filterKeys, filterValues })
  yield put(Actions.updateIndexedItems(indexedItems));
  yield call(updateFilterOptionsSaga)
}

function* updateFilterOptionsSaga() {
  const data = yield select(state => state.MaintenanceConfigurationReducer)
  const { items, indexedItems, filterKeys } = data
  const options = getFilterOptions(items, indexedItems, filterKeys);

  if (options) {
    return yield put(Actions.updateFilterOptions(options))
  }
}

function* createItemSaga({ endpoint, formData }) {
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(Actions.createItemSuccess())
  }

  try {
    const { status } = yield call(api.post, `${url}/workflow/${endpoint}`, {
      ...formData
    });

    if (status === 201) {
      message.success('Operação realizada com sucesso.', 3);
      yield call(fetchItemsSagas, { endpoint })
      return yield put(Actions.createItemSuccess());
    }

    return yield put(Actions.createItemError());
  } catch (error) {
    message.error("Erro na criação, tente novamente", 3)
    yield put(Actions.createItemError());
  }
}

function* editItemSaga({ endpoint, id, formData }) {
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(Actions.editItemSuccess())
  }

  try {
    const { status } = yield call(api.patch, `${url}/workflow/${endpoint}/${id}`, {
      id,
      ...formData
    })
    if (status === 200) {
      message.success('Edição realizada com sucesso.', 3);
      yield call(fetchItemsSagas, { endpoint })
      return yield put(Actions.editItemSuccess())
    }
    return yield put(Actions.editItemError());
  } catch (error) {
    message.error("Erro na edição dos dados, tente novamente", 3)
    return yield put(Actions.editItemError())
  }
}

function* desativeItemSaga({ endpoint, id }) {
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(Actions.desativeItemSuccess())
  }

  try {
    const { status } = yield call(api.delete, `${url}/workflow/${endpoint}/${id}`);

    if (status === 200) {
      message.success('Desativação realizada com sucesso.', 3);
      yield call(fetchItemsSagas, { endpoint })
      return yield put(Actions.desativeItemSuccess())
    }
    return yield put(Actions.desativeItemError());
  } catch (error) {
    message.error("Erro na desativação dos dados, tente novamente", 3)
    return yield put(Actions.desativeItemError())
  }
}
export default function* () {
  yield all([
    takeLatest(Types.FETCH_ITEMS, fetchItemsSagas),
    takeLatest(Types.FETCH_CATEGORIES, fetchCategoriesSagas),
    takeLatest(Types.FETCH_TYPES_DAMAGES, fetchTypesDamagesSagas),
    takeLatest(Types.UPDATE_FILTER_VALUES, filterItemsSagas),
    takeLatest(Types.CREATE_ITEM, createItemSaga),
    takeLatest(Types.EDIT_ITEM, editItemSaga),
    takeLatest(Types.DESATIVE_ITEM, desativeItemSaga)
  ]);
}