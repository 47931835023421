import _ from 'lodash';
import {
  all,
  call,
  put,
  takeLatest,
  select,
  takeEvery
} from 'redux-saga/effects';

import listFilter, { getFilterOptions } from '@/helpers/listFilter';
import { createCollection, batchNestedCollections } from '@/helpers/objUpdater';
import mocks from '@/mocks/kpi_dashboard.json';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

import { FILTER_KEYS } from '../constants.json';
import Actions, { Types, getAll, getLocations, getFranchises } from './reducer';

function* initialSyncSaga() {
  yield all([put(Actions.syncData())]);
}

function* syncDataSaga() {
  try {

    if (process.env.REACT_APP_MOCK === 'true') {
      const { franchises, locations } = mocks;

      const franchisesKeys = createCollection({ list: franchises, key: 'id' });
      const locationsKeys = createCollection({ list: locations, key: 'id' });

      return yield all([
        put(
          Actions.syncDataSuccess(
            franchises,
            franchisesKeys,
            locations,
            locationsKeys
          )
        ),
        put(Actions.syncItems())
      ]);
    }
    const {
      data: { franchises, stores: locations },
      status
    } = yield call(api.get, '/dashboard/kpis');

    const locationsList = batchNestedCollections({
      collection: locations,
      key: 'kpi'
    });
    // const franchisesList = batchNestedCollections({collection: franchises, key: 'kpi'});

    const franchisesKeys = createCollection({ list: franchises, key: 'id' });
    const locationsKeys = createCollection({ list: locations, key: 'id' });

    if (status === 200) {
      return yield all([
        put(
          Actions.syncDataSuccess(
            franchises,
            franchisesKeys,
            locationsList,
            locationsKeys
          )
        ),
        put(Actions.syncItems())
      ]);
    }

    return yield put(Actions.synDataError());
  } catch (error) {
    yield put(Actions.synDataError());
  }
}

function* syncItemsSaga() {
  try {
    const [locations, locationsKeys] = yield all([
      select(getLocations.locations),
      select(getLocations.keys)
    ]);

    const [franchises, franchisesKeys] = yield all([
      select(getFranchises.franchises),
      select(getFranchises.keys)
    ]);

    if (!_.isEmpty(locationsKeys) && !_.isEmpty(franchisesKeys)) {
      const items = _.map(locations, location => {
        const franchise =
          franchises[franchisesKeys[location.franchise_id]] || null;
        return {
          ...location,
          avg_points: franchise ? franchise.avg_points : null,
          franchise: { name: franchise ? franchise.name : null }
        };
      });

      if (!_.isEmpty(items)) {
        const keys = createCollection({ list: items, key: 'id' });

        return yield all([put(Actions.syncItemsSuccess(items, keys))]);
      }
      return yield put(Actions.syncItemsError());
    }
  } catch (error) {
    return yield put(Actions.syncItemsError());
  }
}

function* filterItemsSaga() {
  const data = yield select(getAll);
  const { items, filterValues } = data;

  const indexedItems = listFilter({
    content: items,
    filterKeys: FILTER_KEYS,
    filterValues
  });

  yield put(Actions.setIndexedItems(indexedItems));

  yield call(updateFilterOptionsSaga);
}

function* updateFilterOptionsSaga() {
  const data = yield select(getAll);
  const { items, indexedItems } = data;

  const options = getFilterOptions(items, indexedItems, FILTER_KEYS);

  if (options) {
    return yield put(Actions.updateFilterOptions(options));
  }
}

export default function*() {
  return yield all([
    takeLatest(Types.SYNC, initialSyncSaga),
    takeEvery(Types.SYNC_DATA, syncDataSaga),
    // takeLatest(Types.SYNC_LOCATIONS, syncLocationsSaga),
    takeLatest(Types.SYNC_ITEMS, syncItemsSaga),
    takeLatest(Types.UPDATE_FILTER_VALUES, filterItemsSaga)
  ]);
}
