import { message } from 'antd';
import _ from 'lodash';
import { put, all, takeLatest, select, call } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

import Actions, { Types } from './reducer';

function* fetchCallSagas(actions) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { callId } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}`;

  try {
    const { data: {data}, status } = yield call(api.get, url);
    if (status === 200) {
      return yield put(Actions.fetchCallSuccess(data));
    }
    return yield put(Actions.fetchCallError());
  } catch (e) {
    message.error('Erro ao tentar buscar os chamados, tente novamente.', 3);
    yield put(Actions.fetchCallError());
  }
}

function* fetchDamagesListSagas(actions) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { callId } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}/damages`;

  try {
    const { data, status } = yield call(api.get, url);
    if (status === 200) {
      return yield put(Actions.fetchDamagesListSuccess(data));
    }
    return yield put(Actions.fetchDamagesListError());
  } catch (e) {
    message.error(
      'Erro ao tentar buscar a lista de avarias, tente novamente.',
      3
    );
    yield put(Actions.fetchDamagesListError());
  }
}

function* fetchTeamListSagas(actions) {
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/teams`;

  try {
    const { data, status } = yield call(api.get, url);
    if (status === 200) {
      return yield put(Actions.fetchTeamListSuccess(data));
    }
    return yield put(Actions.fetchTeamListError());
  } catch (e) {
    message.error(
      'Erro ao tentar buscar a listagem de equipes, tente novamente.',
      3
    );
    yield put(Actions.fetchTeamListError());
  }
}

function* fetchProviderListSagas(actions) {
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/technicals`;

  try {
    const { data, status } = yield call(api.get, url);
    if (status === 200) {
      return yield put(Actions.fetchProviderListSuccess(data));
    }
    return yield put(Actions.fetchProviderListError());
  } catch (e) {
    message.error(
      'Erro ao tentar buscar a listagem de prestadores de serviço, tente novamente.',
      3
    );
    yield put(Actions.fetchProviderListError());
  }
}

function* fetchVehicleListSagas(actions) {
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/vehicles`;

  try {
    const { data, status } = yield call(api.get, url);
    if (status === 200) {
      return yield put(Actions.fetchVehicleListSuccess(data));
    }
    return yield put(Actions.fetchVehicleListError());
  } catch (e) {
    message.error(
      'Erro ao tentar buscar a listagem de veículos, tente novamente.',
      3
    );
    yield put(Actions.fetchVehicleListError());
  }
}

function* fetchTeamListCallSagas(actions) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { callId } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}/service_provider`;

  try {
    const { data, status } = yield call(api.get, url);
    if (status === 200) {
      return yield put(Actions.fetchTeamListCallSuccess(data));
    }
    return yield put(Actions.fetchTeamListCallError());
  } catch (e) {
    message.error(
      'Erro ao tentar buscar as informações de técnico/equipe, tente novamente.',
      3
    );
    yield put(Actions.fetchTeamListCallError());
  }
}

function* fetchFilesListSagas(actions) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { callId } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}/analyze_budgets`;

  try {
    const { data, status } = yield call(api.get, url);
    if (status === 200) {
      return yield put(Actions.fetchFilesListSuccess(data));
    }
    return yield put(Actions.fetchFilesListError());
  } catch (e) {
    message.error(
      'Erro ao tentar buscar a lista de arquivos, tente novamente.',
      3
    );
    yield put(Actions.fetchFilesListError());
  }
}

function* fetchAllDamagesSagas(actions) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const {
    call: {
      type 
    }
  } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/damages/list_damages`;

  try {
    const { data, status } = yield call(api.get, url, {
      params: {
        operation: type,
        type_reject: null
      }
    });
    if (status === 200) {
      return yield put(Actions.fetchAllDamagesSuccess(data));
    }
    return yield put(Actions.fetchAllDamagesError());
  } catch (e) {
    message.error(
      'Erro ao tentar buscar a lista de avarias, tente novamente.',
      3
    );
    yield put(Actions.fetchAllDamagesError());
  }
}

function* fetchEvaluationOptionsSagas(actions) {
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/evaluations/status`;

  try {
    const { data, status } = yield call(api.get, url);
    if (status === 200) {
      return yield put(Actions.fetchEvaluationOptionsSuccess(data));
    }
    return yield put(Actions.fetchEvaluationOptionsError());
  } catch (e) {
    message.error(
      'Erro ao tentar buscar a lista de arquivos, tente novamente.',
      3
    );
    yield put(Actions.fetchEvaluationOptionsError());
  }
}

function* updateDamagePriceSagas(actions) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { callId, damageId, newPrice } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}/call_damages/${damageId}`;

  try {
    const { data, status } = yield call(api.put, url, { budget: newPrice });
    if (status === 200) {
      return yield put(Actions.updateDamagePriceSuccess(data));
    }
    return yield put(Actions.updateDamagePriceError());
  } catch (e) {
    message.error(
      'Erro ao tentar atualizar o preço da avaria, tente novamente.',
      3
    );
    yield put(Actions.updateDamagePriceError());
  }
}

function* createDamageSagas(action) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { callId, createDamageInfo } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}/call_damages`;
  
  try {
    const { status } = yield call(api.post, url, createDamageInfo);
    if (status === 200) {
      return yield put(Actions.createDamageSuccess());
    }
    return yield put(Actions.createDamageError());
  } catch (e) {
    message.error('Erro ao tentar adicionar avaria, tente novamente.', 3);
    yield put(Actions.createDamageError());
  }
}

function* createProviderSagas(action) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { createProviderInfo } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/technicals`;
  try {
    const { status } = yield call(api.post, url, createProviderInfo);
    if (status === 200) {
      return yield put(Actions.createProviderSuccess());
    }
    return yield put(Actions.createProviderError());
  } catch (e) {
    message.error(
      'Erro ao tentar adicionar prestador de serviço, tente novamente.',
      3
    );
    yield put(Actions.createProviderError());
  }
}

function* createScheduleSagas(action) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { callId, createScheduleInfo } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}/schedulers`;
  console.log('createInfo', createScheduleInfo);
  try {
    const { status } = yield call(api.post, url, createScheduleInfo);
    if (status === 200) {
      return yield put(Actions.createScheduleSuccess());
    }
    return yield put(Actions.createScheduleError());
  } catch (e) {
    message.error('Erro ao tentar criar os agendamentos, tente novamente.', 3);
    yield put(Actions.createScheduleError());
  }
}

function* createVehicleSagas(action) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { createVehicleInfo } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/vehicles`;
  console.log('createInfo', createVehicleInfo);
  try {
    const { status } = yield call(api.post, url, createVehicleInfo);
    if (status === 200) {
      return yield put(Actions.createVehicleSuccess());
    }
    return yield put(Actions.createVehicleError());
  } catch (e) {
    message.error('Erro ao tentar criar o veículo, tente novamente.', 3);
    yield put(Actions.createVehicleError());
  }
}

function* submitFileSagas(action) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { callId, file } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}/analyze_budgets`;
  try {
    const { status } = yield call(api.post, url, file);
    if (status === 200) {
      return yield put(Actions.submitFileSuccess());
    }
    return yield put(Actions.submitFileError());
  } catch (e) {
    message.error(
      'Erro ao tentar enviar arquivo de orçamento, tente novamente.',
      3
    );
    yield put(Actions.submitFileError());
  }
}

function* submitFinishedServiceSagas(action) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { callId, serviceInfos } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}/call_services`;
  try {
    const { status } = yield call(api.post, url, {
      service_params: serviceInfos
    });
    if (status === 200) {
      return yield put(Actions.submitFinishedServiceSuccess());
    }
    return yield put(Actions.submitFinishedServiceError());
  } catch (e) {
    message.error(
      'Erro ao tentar enviar as informações de finalização do serviço, tente novamente.',
      3
    );
    yield put(Actions.submitFinishedServiceError());
  }
}

function* submitSamsungValidationSagas(action) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { callId, samsungValidationInfos } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}/samsung_validation`;
  try {
    const { status } = yield call(api.post, url, samsungValidationInfos);
    if (status === 200) {
      return yield put(Actions.submitSamsungValidationSuccess());
    }
    return yield put(Actions.submitSamsungValidationError());
  } catch (e) {
    message.error(
      'Erro ao tentar enviar as informações da validação samsung, tente novamente.',
      3
    );
    yield put(Actions.submitSamsungValidationError());
  }
}

function* acceptStatusSagas(action) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { callStatus, callId } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}`;
  try {
    const { status } = yield call(api.put, url, {
      status: callStatus
    });
    if (status === 200) {
      return yield put(Actions.acceptStatusSuccess());
    }
    return yield put(Actions.acceptStatusError());
  } catch (e) {
    message.error(
      'Erro ao tentar aceitar status do chamado, tente novamente.',
      3
    );
    yield put(Actions.acceptStatusError());
  }
}

function* refusedStatusSagas(action) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { reasonForRefused, callId } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}/refused_calls`;
  try {
    const { status } = yield call(api.post, url, {
      reason_refuses: reasonForRefused
    });
    if (status === 200) {
      return yield put(Actions.refusedStatusSuccess());
    }
    return yield put(Actions.refusedStatusError());
  } catch (e) {
    message.error(
      'Erro ao tentar aceitar status do chamado, tente novamente.',
      3
    );
    yield put(Actions.refusedStatusError());
  }
}

function* doneCallSagas(action) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { datePayment, callId } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}/done_calls`;
  try {
    const { status } = yield call(api.post, url, {
      payment: datePayment
    });
    if (status === 200) {
      return yield put(Actions.doneCallSuccess());
    }
    return yield put(Actions.doneCallError());
  } catch (e) {
    message.error(
      'Erro ao tentar liberar pagamento do chamado, tente novamente.',
      3
    );
    yield put(Actions.doneCallError());
  }
}

function* rescheduleSagas(action) {
  const dataReducer = yield select(state => state.InfoMaintenanceReducer);
  const { reasonForRefused, callId } = dataReducer;
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls/${callId}/rescheduler`;
  try {
    const { status } = yield call(api.post, url, {
      reason_refuses_scheduler: reasonForRefused
    });
    if (status === 200) {
      return yield put(Actions.rescheduleSuccess());
    }
    return yield put(Actions.rescheduleError());
  } catch (e) {
    message.error(
      'Erro ao tentar reagendar o chamado, tente novamente.',
      3
    );
    yield put(Actions.rescheduleError());
  }
}

export default function*() {
  yield all([
    takeLatest(Types.FETCH_CALL, fetchCallSagas),
    takeLatest(Types.FETCH_DAMAGES_LIST, fetchDamagesListSagas),
    takeLatest(Types.FETCH_TEAM_LIST, fetchTeamListSagas),
    takeLatest(Types.FETCH_PROVIDER_LIST, fetchProviderListSagas),
    takeLatest(Types.FETCH_VEHICLE_LIST, fetchVehicleListSagas),
    takeLatest(Types.FETCH_TEAM_LIST_CALL, fetchTeamListCallSagas),
    takeLatest(Types.FETCH_FILES_LIST, fetchFilesListSagas),
    takeLatest(Types.UPDATE_DAMAGE_PRICE, updateDamagePriceSagas),
    takeLatest(Types.FETCH_ALL_DAMAGES, fetchAllDamagesSagas),
    takeLatest(Types.CREATE_DAMAGE, createDamageSagas),
    takeLatest(Types.CREATE_PROVIDER, createProviderSagas),
    takeLatest(Types.CREATE_SCHEDULE, createScheduleSagas),
    takeLatest(Types.CREATE_VEHICLE, createVehicleSagas),
    takeLatest(Types.SUBMIT_FILE, submitFileSagas),
    takeLatest(Types.SUBMIT_FINISHED_SERVICE, submitFinishedServiceSagas),
    takeLatest(Types.SUBMIT_SAMSUNG_VALIDATION, submitSamsungValidationSagas),
    takeLatest(Types.ACCEPT_STATUS, acceptStatusSagas),
    takeLatest(Types.REFUSED_STATUS, refusedStatusSagas),
    takeLatest(Types.DONE_CALL, doneCallSagas),
    takeLatest(Types.RESCHEDULE, rescheduleSagas),
    takeLatest(Types.FETCH_EVALUATION_OPTIONS, fetchEvaluationOptionsSagas),
  ]);
}
