import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    fetchCall: ['id'],
    fetchCallSuccess: ['call'],
    fetchCallError: null,
    fetchDamagesList: null,
    fetchDamagesListSuccess: ['damagesList'],
    fetchDamagesListError: null,
    fetchDamageInfo: ['damageId'],
    fetchDamageInfoSuccess: ['damageInfo'],
    fetchDamageInfoError: null,
    fetchTeamList: null,
    fetchTeamListSuccess: ['teamList'],
    fetchTeamListError: null,
    fetchProviderList: null,
    fetchProviderListSuccess: ['providerList'],
    fetchProviderListError: null,
    fetchVehicleList: null,
    fetchVehicleListSuccess: ['vehicleList'],
    fetchVehicleListError: null,
    fetchTeamListCall: null,
    fetchTeamListCallSuccess: ['teamListCall'],
    fetchTeamListCallError: null,
    fetchFilesList: null,
    fetchFilesListSuccess: ['filesList'],
    fetchFilesListError: null,
    fetchAllDamages: null,
    fetchAllDamagesSuccess: ['allDamages'],
    fetchAllDamagesError: null,
    fetchEvaluationOptions: null,
    fetchEvaluationOptionsSuccess:['evaluationOptions'],
    fetchEvaluationOptionsError: null,
    updateDamagePrice: ['damageId', 'newPrice'],
    updateDamagePriceSuccess: null,
    updateDamagePriceError: null,
    createDamage: ['createDamageInfo'],
    createDamageSuccess: null,
    createDamageError: null,
    createProvider: ['createProviderInfo'],
    createProviderSuccess: null,
    createProviderError: null,
    createSchedule: ['createScheduleInfo'],
    createScheduleSuccess: null,
    createScheduleError: null,
    createVehicle: ['createVehicleInfo'],
    createVehicleSuccess: null,
    createVehicleError: null,
    submitFile: ['file'],
    submitFileSuccess: null,
    submitFileError: null,
    acceptStatus: ['callStatus'],
    acceptStatusSuccess: null,
    acceptStatusError: null,
    refusedStatus: ['reasonForRefused'],
    refusedStatusSuccess: null,
    refusedStatusError: null,
    submitFinishedService: ['serviceInfos'],
    submitFinishedServiceSuccess: null,
    submitFinishedServiceError: null,
    submitSamsungValidation: ['samsungValidationInfos'],
    submitSamsungValidationSuccess: null,
    submitSamsungValidationError: null,
    doneCall: ['datePayment'],
    doneCallSuccess: null,
    doneCallError: null,
    reschedule: ['reasonForRefused'],
    rescheduleSuccess: null,
    rescheduleError: null,
    resetReducer: null
  },
  { prefix: 'infoMaintenance' }
);

export { Types };
export default Creators;

export const INITIAL_STATE = Immutable({
  call: {},
  callId: null,
  loadingCall: true,
  damagesList: [],
  loadingDamagesList: false,
  damageId: null,
  newPrice: '',
  teamList: [],
  loadingTeamList: false,
  providerList: [],
  loadingProviderList: false,
  vehicleList: [],
  loadingVehicleList: false,
  teamListCall: [],
  loadingTeamListCall: false,
  filesList: [],
  loadingFilesList: false,
  loadingUpdateDamagePrice: false,
  allDamages: [],
  loadingAllDamages: false,
  createDamageInfo: {},
  loadingCreateDamage: false,
  createProviderInfo: {},
  loadingCreateProvider: false,
  createScheduleInfo: {},
  loadingCreateSchedule: false,
  createVehicleInfo: {},
  loadingCreateVehicle: false,
  file: null,
  loadingSubmitFile: false,
  callStatus: '',
  reasonForRefused: '',
  loadingAcceptStatus: false,
  loadingRefusedStatus: false,
  serviceInfos: {},
  loadingSubmitFinishedService: false,
  samsungValidationInfos: {},
  loadingSubmitSamsungValidation: false,
  datePayment: '',
  loadingDoneCall: false,
  evaluationOptions: [],
  loadingEvaluationOptions: false,
  err: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_CALL]: (state, { id }) => ({
    ...state,
    damagesList: [],
    callId: id,
    loadingCall: true
  }),
  [Types.FETCH_CALL_SUCCESS]: (state, { call }) => ({
    ...state,
    call,
    loadingCall: false
  }),
  [Types.FETCH_CALL_ERROR]: state => ({
    ...state,
    loadingCall: false
  }),
  [Types.FETCH_DAMAGES_LIST]: state => ({
    ...state,
    loadingDamagesList: true
  }),
  [Types.FETCH_DAMAGES_LIST_SUCCESS]: (state, { damagesList }) => ({
    ...state,
    damagesList,
    loadingDamagesList: false
  }),
  [Types.FETCH_DAMAGES_LIST_ERROR]: state => ({
    ...state,
    loadingDamagesList: false
  }),
  [Types.FETCH_TEAM_LIST]: state => ({
    ...state,
    loadingTeamList: true
  }),
  [Types.FETCH_TEAM_LIST_SUCCESS]: (state, { teamList }) => ({
    ...state,
    teamList,
    loadingTeamList: false
  }),
  [Types.FETCH_TEAM_LIST_ERROR]: state => ({
    ...state,
    loadingTeamList: false
  }),
  [Types.FETCH_PROVIDER_LIST]: state => ({
    ...state,
    loadingProviderList: true
  }),
  [Types.FETCH_PROVIDER_LIST_SUCCESS]: (state, { providerList }) => ({
    ...state,
    providerList,
    loadingProviderList: false
  }),
  [Types.FETCH_PROVIDER_LIST_ERROR]: state => ({
    ...state,
    loadingProviderList: false
  }),
  [Types.FETCH_VEHICLE_LIST]: state => ({
    ...state,
    loadingVehicleList: true
  }),
  [Types.FETCH_VEHICLE_LIST_SUCCESS]: (state, { vehicleList }) => ({
    ...state,
    vehicleList,
    loadingVehicleList: false
  }),
  [Types.FETCH_VEHICLE_LIST_ERROR]: state => ({
    ...state,
    loadingVehicleList: false
  }),
  [Types.FETCH_TEAM_LIST_CALL]: state => ({
    ...state,
    loadingTeamListCall: true
  }),
  [Types.FETCH_TEAM_LIST_CALL_SUCCESS]: (state, { teamListCall }) => ({
    ...state,
    teamListCall,
    loadingTeamListCall: false
  }),
  [Types.FETCH_TEAM_LIST_CALL_ERROR]: state => ({
    ...state,
    loadingTeamListCall: false
  }),
  [Types.FETCH_FILES_LIST]: state => ({
    ...state,
    loadingFilesList: true
  }),
  [Types.FETCH_FILES_LIST_SUCCESS]: (state, { filesList }) => ({
    ...state,
    filesList,
    loadingFilesList: false
  }),
  [Types.FETCH_FILES_LIST_ERROR]: state => ({
    ...state,
    loadingFilesList: false
  }),
  [Types.FETCH_ALL_DAMAGES]: state => ({
    ...state,
    loadingAllDamages: true
  }),
  [Types.FETCH_ALL_DAMAGES_SUCCESS]: (state, { allDamages }) => ({
    ...state,
    allDamages,
    loadingAllDamages: false
  }),
  [Types.FETCH_ALL_DAMAGES_ERROR]: state => ({
    ...state,
    loadingAllDamages: false
  }),
  [Types.FETCH_EVALUATION_OPTIONS]: state => ({
    ...state,
    loadingEvaluationOptions: true
  }),
  [Types.FETCH_EVALUATION_OPTIONS_SUCCESS]: (state, { evaluationOptions }) => ({
    ...state,
    evaluationOptions,
    loadingEvaluationOptions: false
  }),
  [Types.FETCH_EVALUATION_OPTIONS_ERROR]: state => ({
    ...state,
    loadingEvaluationOptions: false
  }),
  [Types.UPDATE_DAMAGE_PRICE]: (state, { damageId, newPrice }) => ({
    ...state,
    newPrice,
    damageId,
    loadingUpdateDamagePrice: true,
    err: false
  }),
  [Types.UPDATE_DAMAGE_PRICE_SUCCESS]: state => ({
    ...state,
    loadingUpdateDamagePrice: false
  }),
  [Types.UPDATE_DAMAGE_PRICE_ERROR]: state => ({
    ...state,
    loadingUpdateDamagePrice: false,
    err: true
  }),
  [Types.CREATE_DAMAGE]: (state, { createDamageInfo }) => ({
    ...state,
    createDamageInfo,
    loadingCreateDamage: true,
    err: false
  }),
  [Types.CREATE_DAMAGE_SUCCESS]: state => ({
    ...state,
    loadingCreateDamage: false
  }),
  [Types.CREATE_DAMAGE_ERROR]: state => ({
    ...state,
    loadingCreateDamage: false,
    err: true
  }),
  [Types.CREATE_PROVIDER]: (state, { createProviderInfo }) => ({
    ...state,
    createProviderInfo,
    loadingCreateProvider: true,
    err: false
  }),
  [Types.CREATE_PROVIDER_SUCCESS]: state => ({
    ...state,
    loadingCreateProvider: false
  }),
  [Types.CREATE_PROVIDER_ERROR]: state => ({
    ...state,
    loadingCreateProvider: false,
    err: true
  }),
  [Types.CREATE_SCHEDULE]: (state, { createScheduleInfo }) => ({
    ...state,
    createScheduleInfo,
    loadingCreateSchedule: true,
    err: false
  }),
  [Types.CREATE_SCHEDULE_SUCCESS]: state => ({
    ...state,
    loadingCreateSchedule: false
  }),
  [Types.CREATE_SCHEDULE_ERROR]: state => ({
    ...state,
    loadingCreateSchedule: false,
    err: true
  }),
  [Types.CREATE_VEHICLE]: (state, { createVehicleInfo }) => ({
    ...state,
    createVehicleInfo,
    loadingCreateVehicle: true,
    err: false
  }),
  [Types.CREATE_VEHICLE_SUCCESS]: state => ({
    ...state,
    loadingCreateVehicle: false
  }),
  [Types.CREATE_VEHICLE_ERROR]: state => ({
    ...state,
    loadingCreateVehicle: false,
    err: true
  }),
  [Types.SUBMIT_FILE]: (state, { file }) => ({
    ...state,
    file,
    loadingSubmitFile: true,
    err: false
  }),
  [Types.SUBMIT_FILE_SUCCESS]: state => ({
    ...state,
    loadingSubmitFile: false
  }),
  [Types.SUBMIT_FILE_ERROR]: state => ({
    ...state,
    loadingSubmitFile: false,
    err: true
  }),
  [Types.SUBMIT_FINISHED_SERVICE]: (state, { serviceInfos }) => ({
    ...state,
    serviceInfos,
    loadingSubmitFinishedService: true,
    err: false
  }),
  [Types.SUBMIT_FINISHED_SERVICE_SUCCESS]: state => ({
    ...state,
    loadingSubmitFinishedService: false
  }),
  [Types.SUBMIT_FINISHED_SERVICE_ERROR]: state => ({
    ...state,
    loadingSubmitFinishedService: false,
    err: true
  }),
  [Types.SUBMIT_SAMSUNG_VALIDATION]: (state, { samsungValidationInfos }) => ({
    ...state,
    samsungValidationInfos,
    loadingSubmitSamsungValidation: true,
    err: false
  }),
  [Types.SUBMIT_SAMSUNG_VALIDATION_SUCCESS]: state => ({
    ...state,
    loadingSubmitSamsungValidation: false
  }),
  [Types.SUBMIT_SAMSUNG_VALIDATION_ERROR]: state => ({
    ...state,
    loadingSubmitSamsungValidation: false,
    err: true
  }),
  [Types.ACCEPT_STATUS]: (state, { callStatus }) => ({
    ...state,
    callStatus,
    loadingAcceptStatus: true,
    err: false,
  }),
  [Types.ACCEPT_STATUS_SUCCESS]: state => ({
    ...state,
    loadingAcceptStatus: false
  }),
  [Types.ACCEPT_STATUS_ERROR]: state => ({
    ...state,
    loadingAcceptStatus: false,
    err: true
  }),
  [Types.REFUSED_STATUS]: (state, {reasonForRefused}) => ({
    ...state,
    reasonForRefused,
    loadingRefusedStatus: true,
    err: false,
  }),
  [Types.REFUSED_STATUS_SUCCESS]: state => ({
    ...state,
    loadingRefusedStatus: false
  }),
  [Types.REFUSED_STATUS_ERROR]: state => ({
    ...state,
    loadingRefusedStatus: false,
    err: true
  }),
  [Types.DONE_CALL]: (state, {datePayment}) => ({
    ...state,
    datePayment,
    loadingDoneCall: true,
    err: false,
  }),
  [Types.DONE_CALL_SUCCESS]: state => ({
    ...state,
    loadingDoneCall: false,
  }),
  [Types.DONE_CALL_ERROR]: state => ({
    ...state,
    loadingDoneCall: false,
    err: true,
  }),
  [Types.RESCHEDULE]: (state, {reasonForRefused}) => ({
    ...state,
    reasonForRefused,
    loadingReschedule: true,
    err: false,
  }),
  [Types.RESCHEDULE_SUCCESS]: state => ({
    ...state,
    loadingReschedule: false,
  }),
  [Types.RESCHEDULE_ERROR]: state => ({
    ...state,
    loadingReschedule: false,
    err: true,
  }),
  [Types.RESET_REDUCER]: state => ({ ...INITIAL_STATE })
});

const PATH = 'InfoMaintenanceReducer';

const root = selector => {
  return state => state[PATH][selector];
};

export const call = {
  getCall: root('call'),
  getLoading: root('loadingCall')
};

export const damages = {
  getDamages: root('damagesList'),
  getLoading: root('loadingDamagesList')
};

export const teamList = {
  getTeamList: root('teamList'),
  getLoading: root('loadingTeamList')
};

export const providerList = {
  getProviderList: root('providerList'),
  getLoading: root('loadingProviderList')
};

export const vehicleList = {
  getVehicleList: root('vehicleList'),
  getLoading: root('loadingVehicleList')
};

export const teamCall = {
  getTeamListCall: root('teamListCall'),
  getLoading: root('loadingTeamListCall')
};

export const files = {
  getFilesList: root('filesList'),
  getLoading: root('loadingFilesList')
};

export const allDamages = {
  getAllDamages: root('allDamages'),
  getLoading: root('loadingAllDamages')
};

export const updatePrice = {
  getLoading: root('loadingUpdateDamagePrice')
};

export const createDamageProps = {
  getLoading: root('loadingCreateDamage')
};

export const createProviderProps = {
  getLoading: root('loadingCreateProvider')
};

export const createScheduleProps = {
  getLoading: root('loadingCreateSchedule')
};

export const createVehicleProps = {
  getLoading: root('loadingCreateVehicle')
}

export const submitFileInfo = {
  getLoading: root('loadingSubmitFile')
};

export const submitFinishedServiceInfo = {
  getLoading: root('loadingSubmitFinishedService')
}

export const submitSamsungValidationInfo = {
  getLoading: root('loadingSubmitSamsungValidation')
}

export const error = {
  getError: root('err')
};

export const doneCallInfo = {
  getLoading: root('loadingDoneCall')
}

export const rescheduleInfo = {
  getLoading: root('loadingReschedule')
}

export const evaluationOptions = {
  getLoading: root('loadingEvaluationOptions'),
  getOptions: root('evaluationOptions')
}

export const transitionStatus = {
  getAcceptStatusLoading: root('loadingAcceptStatus'),
  getRefusedStatusLoading: root('loadingRefusedStatus')
}
