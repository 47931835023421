import React from 'react';

import PrivateRoute from '@/routes/PrivateRoute';

const Tickets = React.lazy(() => import('@/containers/Tickets'));
const Ranking = React.lazy(() => import('@/containers/Ranking'));
const VisitsDashboard = React.lazy(() => import('@/containers/VisitsDashboard'));
const KpiDashboard = React.lazy(() => import('@/containers/KpiDashboard'));
const StoreSheet = React.lazy(() => import('@/containers/StoreSheet'));
const Gallery = React.lazy(() => import('@/containers/Gallery'));
const Communication = React.lazy(() => import('@/containers/Communication'))
const Maintenance = React.lazy(() => import('@/containers/Maintenance'))
const MaintenanceConfiguration = React.lazy(() => import('@/containers/MaintenanceConfiguration'))
const InfoMaintenance = React.lazy(() => import('@/containers/InfoMaintenance'))
const Pricing = React.lazy(() => import('@/containers/Pricing'))

const appRoutes = {
  exact: true,
  path: '/tickets',
  name: 'Tickets',
  label: 'tickets.title',
  key: 'tickets',
  component: Tickets,
  route: PrivateRoute,
  children: [
    {
      path: '/ranking',
      label: 'ranking.title',
      name: 'StoreRanking',
      key: 'Ranking',
      component: Ranking,
      route: PrivateRoute,
      restricted: true,
    },
    {
      path: '/visits_dashboard',
      label: 'dashboard.title',
      name: 'VisitsDashboard',
      key: 'VisitsDashboard',
      component: VisitsDashboard,
      route: PrivateRoute,
      restricted: true,
    },
    {
      path: '/kpi_dashboard',
      label: 'dashboard.title',
      name: 'KpiDashboard',
      key: 'KpiDashboard',
      component: KpiDashboard,
      route: PrivateRoute,
      restricted: true,
    },
    {
      path: '/store_sheet',
      label: 'dashboard.title',
      name: 'StoreSheet',
      key: 'storeSheet',
      component: StoreSheet,
      route: PrivateRoute,
      restricted: true,
    },
    {
      path: '/gallery',
      label: 'dashboard.title',
      name: 'Galeria',
      key: 'gallery',
      component: Gallery,
      route: PrivateRoute,
      restricted: true,
    },
    {
      path: '/communication',
      label: 'communication.title',
      name: 'Communication',
      key: 'communication',
      component: Communication,
      route: PrivateRoute,
      restricted: true

    },
    {
      path: '/maintenance',
      label: 'maintenance.title',
      name: 'Maintenance',
      key: 'maintenance',
      component: Maintenance,
      route: PrivateRoute,
      restricted: true,
    },
    {
      path: '/maintenance_configuration',
      label: 'maintenance_configuration.title',
      name: 'MaintenanceConfiguration',
      key: 'maintenance_configuration',
      component: MaintenanceConfiguration,
      route: PrivateRoute,
      restricted: true,
    },
    {
      path: '/info_maintenance/:id',
      label: 'info_maintenance.title',
      name: 'InfoMaintenance',
      key: 'info_maintenance',
      component: InfoMaintenance,
      route: PrivateRoute,
      restricted: true,
    },
    {
      path: '/pricing',
      label: 'pricing.title',
      name: 'pricing',
      key: 'pricing',
      component: Pricing,
      route: PrivateRoute,
      restricted: true
    }
  ],
};

export default appRoutes;
