import moment from 'moment';
import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions(
  {
    syncContent: null,
    syncContentSuccess: ['content'],
    syncContentError: null,

    updateFilterValues: ['filterValues'],
    updateFilterOptions: ['filterOptions'],
    updateIndexedItems: ['indexedItems'],

    resetContent: null,
    
    setDateFilter: ['dateFilter'],
    setSearchContent: ['searchContent'],
  },
  { prefix: 'gallery' }
);

export { Types };
export default Creators;

const INITIAL_STATE = {
  content: [],
  indexedItems: [],
  loading: false,

  filterOptions: [],
  filterValues: {},
  dateFilter: { startDate: moment().subtract(5, 'days'), endDate: moment() },
  searchContent: ''
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNC_CONTENT]: state => ({
    ...state,
    loading: true
  }),
  [Types.SYNC_CONTENT_SUCCESS]: (state, { content }) => ({
    ...state,
    content,
    loading: false
  }),
  [Types.SYNC_CONTENT_ERROR]: state => ({
    ...state,
    loading: false
  }),

  [Types.UPDATE_FILTER_VALUES]: (state, { filterValues }) => ({
    ...state,
    filterValues
  }),
  [Types.UPDATE_FILTER_OPTIONS]: (state, { filterOptions }) => ({
    ...state,
    filterOptions
  }),
  [Types.UPDATE_INDEXED_ITEMS]: (state, { indexedItems }) => ({
    ...state,
    indexedItems
  }),
  [Types.SET_DATE_FILTER]: (state, { dateFilter }) => ({
    ...state,
    dateFilter
  }),

  [Types.SET_SEARCH_CONTENT]: (state, { searchContent }) => ({
    ...state,
    searchContent
  }),

  [Types.RESET_CONTENT]: () => INITIAL_STATE
});

const path = 'GalleryReducer';

export const getContent = state => state.GalleryReducer.content;
export const getIndexedItems = state => state.GalleryReducer.indexedItems;
export const getLoading = state => state.GalleryReducer.loading;

export const getDateFilter = state => state.GalleryReducer.dateFilter;

export const getFilter = {
  options: state => state[path].filterOptions,
  values: state => state[path].filterValues
};

export const getSearchContent = state => state.GalleryReducer.searchContent;
