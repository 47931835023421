import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  setWorkspace: ['workspaceId'],
  syncWorkspaces: ['data'],
  syncWorkspacesSuccess: ['workspaces'],
  syncWorkspacesError: null,
  resetWorkspaces: null,
});

export { Types };
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  selectedWorkspace: null,

  loadingWorkspaces: false,
  errorWorkspaces: false,
  workspaces: [],
});

/* Hookup Reducers to Types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_WORKSPACE]: (state, { workspaceId }) => {
    return ({ ...state, selectedWorkspace: workspaceId });
  },
  [Types.SYNC_WORKSPACES]: (state) => {
    return ({ ...state, loadingWorkspaces: true, errorWorkspaces: false });
  },
  [Types.SYNC_WORKSPACES_SUCCESS]: (state, { workspaces }) => {
    return ({ ...state, workspaces, loadingWorkspaces: false, errorWorkspaces: false });
  },
  [Types.SYNC_WORKSPACES_ERROR]: (state) => {
    return ({ ...state, loadingWorkspaces: false, errorWorkspaces: true });
  },
  [Types.RESET_WORKSPACES]: (state) =>  ({ ...INITIAL_STATE }),
});

/* Selectors */

export const currentWorkspace = state => state.WorkspacesReducer.selectedWorkspace;
export const getWorkspaces = state => state.WorkspacesReducer.workspaces;

