import _ from 'lodash';
import moment from 'moment';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    syncPricings: null,
    syncPricingsSuccess: ['pricings'],
    syncPricingsError: null,
    setSearchPricing: ['searchPricing'],

    setFilterOptions: ['filterOptions'],

    setIndexedPricing: ['indexedPricing'],

    createPricingSuccess: null,
    createPricingError: null,
    createPricing: ['formData'],

    editPricing: ['formData', 'pricingId'],
    editPricingSuccess: null,
    editPricingError: null,

    deletePricingSuccess: null,
    deletePricingError: null,
    deletePricing: ['id'],

    sendFormFinish: null,

    setIsNewPricing: ['status'],

    fetchCurrentPricing: ['id'],
    fetchCurrentPricingSuccess: ['currentPricing'],
    fetchCurrentPricingError: null,

    fetchFieldsOptions: null,
    fetchFieldsOptionsSuccess: ['fieldsOptions'],
    fetchFieldsOptionsError: null,

    setPricingFilterValues: ['filterValues'],

    setPricingStatusFilters: ['statusFilters'],

    setFilterDates: ['filterDates'],

    createPdf: null,
    createPdfSuccess: null,
    createPdfError: null,

    resetState: null,
    clearData: null
  },
  { prefix: 'PricingReducer/' }
);

export const INITIAL_STATE = Immutable({
  loadingPricing: false,
  indexedPricing: [],
  pricings: [],
  searchPricing: '',

  statusFilters: [],

  filterOptions: [],

  filterValues: {},

  loadingSendForm: false,

  showPricingFields: false,
  isNewPricing: false,

  currentPricing: {},
  loadingCurrentPricing: false,

  loadingCreatePDF: false,

  fieldsOptions: {},
  loadingFieldsOptions: false,

  filterDates: [
    moment()
      .subtract(90, 'days')
      .format(),
    moment()
      .add(90, 'days')
      .format()
  ],

  filterKeys: {
    categories: { name: 'Categoria', group: 'category', type: 'checkbox' }
  }
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNC_PRICINGS]: state => {
    return { ...state, loadingPricing: true };
  },
  [Types.SYNC_PRICINGS_SUCCESS]: (state, { pricings }) => {
    return {
      ...state,
      pricings,
      loadingPricing: false,
      indexedPricing: Array.from(Array(pricings.length).keys())
    };
  },
  [Types.SYNC_PRICINGS_ERROR]: state => {
    return { ...state, loadingPricing: false };
  },

  [Types.SET_SEARCH_PRICING]: (state, { searchPricing }) => {
    return { ...state, searchPricing };
  },
  [Types.SET_INDEXED_PRICING]: (state, { indexedPricing }) => {
    return { ...state, indexedPricing };
  },
  [Types.SET_IS_NEW_PRICING]: (state, { status }) => {
    return { ...state, isNewPricing: status };
  },

  [Types.FETCH_CURRENT_PRICING]: state => {
    return { ...state, loadingCurrentPricing: true };
  },
  [Types.FETCH_CURRENT_PRICING_SUCCESS]: (state, { currentPricing }) => {
    return { ...state, currentPricing, loadingCurrentPricing: false };
  },
  [Types.FETCH_CURRENT_PRICING_ERROR]: state => {
    return { ...state, loadingCurrentPricing: false };
  },

  [Types.FETCH_FIELDS_OPTIONS]: state => {
    return { ...state, loadingFieldsOptions: true };
  },
  [Types.FETCH_FIELDS_OPTIONS_SUCCESS]: (state, { fieldsOptions }) => {
    return { ...state, fieldsOptions, loadingFieldsOptions: false };
  },
  [Types.FETCH_FIELDS_OPTIONS_ERROR]: state => {
    return { ...state, loadingFieldsOptions: false };
  },

  [Types.CREATE_PRICING]: state => {
    return { ...state, loadingSendForm: true };
  },
  [Types.CREATE_PRICING_SUCCESS]: state => {
    return { ...state, loadingSendForm: false };
  },
  [Types.CREATE_PRICING_ERROR]: state => {
    return { ...state, loadingSendForm: false };
  },

  [Types.EDIT_PRICING]: state => {
    return { ...state, loadingSendForm: true };
  },
  [Types.EDIT_PRICING_SUCCESS]: state => {
    return { ...state, loadingSendForm: false };
  },
  [Types.EDIT_PRICING_ERROR]: state => {
    return { ...state, loadingSendForm: false };
  },

  [Types.DELETE_PRICING]: state => {
    return { ...state, loadingSendForm: true };
  },
  [Types.DELETE_PRICING_SUCCESS]: state => {
    return { ...state, loadingSendForm: false };
  },
  [Types.DELETE_PRICING_ERROR]: state => {
    return { ...state, loadingSendForm: false };
  },

  [Types.SEND_FORM_FINISH]: state => {
    return { ...state, loadingSendForm: false, isNewPricing: false };
  },

  [Types.SET_PRICING_FILTER_VALUES]: (state, { filterValues }) => {
    return { ...state, filterValues: _.pickBy(filterValues) };
  },
  [Types.SET_FILTER_OPTIONS]: (state, { filterOptions }) => {
    return { ...state, filterOptions };
  },
  [Types.SET_FILTER_DATES]: (state, { filterDates }) => {
    return { ...state, filterDates };
  },

  [Types.CREATE_PDF]: state => {
    return { ...state, loadingCreatePDF: true };
  },
  [Types.CREATE_PDF_SUCCESS]: state => {
    return { ...state, loadingCreatePDF: false };
  },
  [Types.CREATE_PDF_ERROR]: state => {
    return { ...state, loadingCreatePDF: false };
  },

  [Types.RESET_STATE]: state => ({ ...INITIAL_STATE }),
  [Types.SET_PRICING_STATUS_FILTERS]: (state, { statusFilters }) => ({
    ...state,
    statusFilters
  }),
  [Types.CLEAR_DATA]: state => {
    return {
      ...state,
      currentPricing: {},
      isNewPricing: false,
    };
  }
});

export { Types };
export default Creators;

// SECTION SELECTORS

const PATH = 'PricingReducer';

const root = selector => {
  return state => state[PATH][selector];
};

export const getPricing = {
  pricings: root('pricings'),
  getOnePricing: (state, index) => state[PATH].pricing[index],
  loadingPricing: root('loadingPricing'),
  getCurrentPricing: root('currentPricing'),
  loadingCurrentPricing: root('loadingCurrentPricing'),
  getFieldsOptions: root('fieldsOptions'),
  loadingFieldsOptions: root('loadingFieldsOptions'),
  indexedPricing: root('indexedPricing'),
  totalPricing: state => state[PATH].indexedPricing.length,
  emptyPricing: state => state[PATH].pricing.length <= 0,
  getSelectedPricingId: root('selectedPricingId'),
  searchPricingValues: root('searchPricing'),
  loadingSendForm: root('loadingSendForm'),
  loadingCreatePDF: root('loadingCreatePDF'),
  getIsNewPricingStatus: root('isNewPricing')
};

export const getFilter = {
  filterOptions: root('filterOptions'),
  filterValues: root('filterValues'),
  emptyFilterOptions: state => state[PATH].filterOptions.length <= 0,
  statusFilters: root('statusFilters'),
  filterDates: root('filterDates')
};
