import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  syncContent: ['contentType'],
  updateContentType: ['contentType'],
  syncContentSuccess: ['contentType', 'content', 'franchisesKpis', 'locationsPositions', 'contentOptions'],
  syncContentError: null,

  updateFilterValues: ['filterValues'],
  updateFilterOptions: ['filterOptions'],

  updateIndexedItems: ['indexedItems'],
  updateFilterKeys: ['filterKeys'],

  updateSorterKeys: ['sorterKeys'],
  updateSorterValues: ['sorterValues'],

  updateExtraKeys: ['extraKeys'],
  updateExtraValues: ['extraValues'],

  setSearchContent: ['searchContent'],

  updateLocalScore: null,
  updateLocalScoreSuccess: ['localScore','localKpi'],
  updateLocalScoreError: null,

  resetContent: null
}, {prefix:'ranking'});

export { Types };
export default Creators;

export const INITIAL_STATE = Immutable({
  contentType: 'locations', // locations / franchises
  content: [],
  
  locationsPositions: {},

  contentOptions: {},

  franchisesKpis: {},

  filterValues: {},
  filterKeys: {},
  filterOptions: {},

  extraKeys: {},
  extraValues: {
    ranking: {
      key: 'ranking',
      values: ['global']
    }
  },

  sorterKeys: {},
  sorterValues: {
    quality: {
      group: 'kpi',
      key: 'quality',
      values: ['Melhores primeiro'],
      params: {
        name: 'Melhores primeiro',
        key: 'all',
        rule: '>'
      }
    },
    kpi: {
      group:'kpi',
      key: 'kpi',
      values:['Total'],
      params: {
        key: "total",
        name: "Total",
        rule: ">"
      },
    }
  },

  indexedItems: [],
  searchContent: '',

  localName: 'Brasil',
  localScore: null,
  localKpi: null,
  loadingLocalScore: false,

  loading: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNC_CONTENT]: state => ({
    ...state,
    loading: true
  }),
  [Types.UPDATE_CONTENT_TYPE]: (state, {contentType}) => ({
    ...state,
    loading: true,
    contentType
  }),
  [Types.SYNC_CONTENT_SUCCESS]: (
    state,
    { contentType, content, franchisesKpis, locationsPositions, contentOptions }
  ) => ({
    ...state,
    content,
    contentType,
    franchisesKpis,
    locationsPositions,
    contentOptions,
    loading: false
  }),
  [Types.SYNC_CONTENT_ERROR]: state => ({
    ...state,
    loading: false
  }),

  [Types.UPDATE_FILTER_KEYS]: (state, { filterKeys }) => ({
    ...state,
    filterKeys
  }),

  [Types.UPDATE_FILTER_VALUES]: (state, { filterValues }) => ({
    ...state,
    filterValues
  }),
  [Types.UPDATE_FILTER_OPTIONS]: (state, { filterOptions }) => ({
    ...state,
    filterOptions
  }),

  [Types.UPDATE_INDEXED_ITEMS]: (state, { indexedItems }) => ({
    ...state,
    indexedItems
  }),

  [Types.SET_SEARCH_CONTENT]: (state, { searchContent }) => ({
    ...state,
    searchContent
  }),

  [Types.RESET_CONTENT]: state => ({...INITIAL_STATE}),

  [Types.UPDATE_SORTER_KEYS]: (state, { sorterKeys }) => ({
    ...state,
    sorterKeys
  }),

  [Types.UPDATE_LOCAL_SCORE]: state => ({
    ...state,
    loadingLocalScore: true
  }),
  [Types.UPDATE_LOCAL_SCORE_SUCCESS]: (state, { localScore, localKpi }) => ({
    ...state,
    localScore,
    localKpi,
    loadingLocalScore: false
  }),
  [Types.UPDATE_LOCAL_SCORE_ERROR]: state => ({
    ...state,
    loadingLocalScore: false
  }),

  [Types.UPDATE_SORTER_VALUES]: (state, { sorterValues }) => ({
    ...state,
    sorterValues
  }),
  
  [Types.UPDATE_EXTRA_KEYS]: (state, { extraKeys }) => ({
    ...state,
    extraKeys
  }),
  
  [Types.UPDATE_EXTRA_VALUES]: (state, { extraValues }) => {
    const { contentOptions } = state;
    const { values } = extraValues?.ranking;
    return ({
    ...state,
    extraValues,
    content: contentOptions[values[0]]
  })}
});

export const getContent = state => state.RankingReducer.content;
export const getContentType = state => state.RankingReducer.contentType;
export const getIndexedItems = state => state.RankingReducer.indexedItems;

export const getLocationsPositions = state => state.RankingReducer.locationsPositions

export const getFilterKeys = state => state.RankingReducer.filterKeys;
export const getFilterValues = state => state.RankingReducer.filterValues;
export const getFilterOptions = state => state.RankingReducer.filterOptions;

export const getSearchContent = state => state.RankingReducer.searchContent;

export const getSorterKeys = state => state.RankingReducer.sorterKeys;
export const getSorterValues = state => state.RankingReducer.sorterValues;

export const getExtraKeys = state => state.RankingReducer.extraKeys;
export const getExtraValues = state => state.RankingReducer.extraValues;

export const getFranchiseKpi = id => state =>
  state.RankingReducer.franchisesKpis[id];

export const isLoading = state => state.RankingReducer.loading;

export const getLocal = {
  score: state => state.RankingReducer.localScore,
  kpi: state => state.RankingReducer.localKpi,
  name: state => state.RankingReducer.localName,
  loading: state => state.RankingReducer.loadingLocalScore,
};
