import _ from 'lodash';
import { all, takeLatest, put, select, call } from 'redux-saga/effects';

import listFilter, { getFilterOptions } from '@/helpers/listFilter';
import { createCollection } from '@/helpers/objUpdater';
// import mocks, { NEW_MOCK as newMock} from '@/mocks/dash_visits.json';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

import { FILTER_KEYS } from '../constants.json';
import Actions, {
  Types,
  getCollaborators,
  getLocations,
  // getWeeks,
  getTickets,
  // getWeeks,
  getFilter
} from './reducer';

function*  initialSync() {
  try {
    const {
      data,
      status
    } = yield call(api.get, `/dashboard/visits.json`);

    if(status === 200) {
      const {
        weeks = [],
        locations = [],
        tickets = [],
        visits = [],
        franchises = []
      } = data;

      yield all([
        call(syncWeeksSaga, weeks),
        call(syncLocationsSaga, locations),
        call(syncFranchisesSaga, franchises),
        call(syncTicketsSaga, tickets),
        call(syncVisitsSaga, visits)
      ]);
      return yield put(Actions.initialSyncSuccess());
    }
  } catch (error) {
    return yield put(Actions.initialSyncError());
  }
}

function* syncWeeksSaga(weeks) {
  try {
    if(weeks && !_.isEmpty(weeks)){
      const weekKeys = _.isEmpty(weeks)
      ? {}
      : _.reduce(weeks, (prev, curr, index) => ({...prev, [curr]: index}), {});
      
      return yield put(Actions.syncWeeksSuccess(weeks, weekKeys));
    }
    return yield put(Actions.syncWeeksError());
    
  } catch (error) {
    return yield put(Actions.syncWeeksError());
  }
}

function* syncVisitsSaga(visits) {
  try {
    if(visits && !_.isEmpty(visits)) {
      const data = {
        collaborators: {},
        cycles: []
      };

      _.forEach(
        visits,
        visit => {
          const {
            collaborator_id: collaboratorId = null,
            name = null,
            location_id: locationId = null,
            cycle = null,
            according_count: accordingCount = 0,
            disagreement_count: disagreementCount = 0,
            not_applied_count: notAppliedCount = 0,
            status = null
          } = visit; 
          
          const actualCollaborator = {name, id:collaboratorId};
          
          const actualCycle = {locationId, accordingCount, disagreementCount, notAppliedCount, status};

          const arrayInject = (prev = [], curr) => prev ? [...prev, curr] : [curr];

          data.collaborators = {
            ...data.collaborators,
            [collaboratorId]: {
              ...data.collaborators[collaboratorId],
              ...actualCollaborator,
              locations: _.union(
                data.collaborators[collaboratorId] 
                  ? data.collaborators[collaboratorId].locations
                  : []
                  , [locationId],
              )
             }
          };

          data.cycles = {
            ...data.cycles,
            [cycle]: {
              ...data.cycles[cycle],
              [collaboratorId]: arrayInject(
                data.cycles[cycle] 
                ? data.cycles[cycle][collaboratorId] 
                : []
                , actualCycle)
            }
          }
      });

      const collaboratorsKeys = _
        .chain(data.collaborators)
        .keys()
        .reduce((prev, curr, index) => ({...prev, [curr]: index}) ,{})
        .value();
      
      const collaborators = _.values(data.collaborators);

      return yield put(Actions.syncVisitsSuccess(collaborators, collaboratorsKeys, data.cycles));
    }

    return yield put(Actions.syncVisitsError())
  } catch (error) {
    return yield put(Actions.syncVisitsError())
  }
}

function* syncFranchisesSaga(franchises) {
  try {
    if (franchises && !_.isEmpty(franchises)) {
      const keys = createCollection({ list: franchises, key: 'id' });

      return yield all([
        put(Actions.syncFranchisesSuccess(franchises, keys)),
      ]);
    }

    return yield put(Actions.syncFranchisesError());
  } catch (error) {
    return yield put(Actions.syncFranchisesError());
  }
}

function* syncLocationsSaga(locations) {
  try {

    if(locations && !_.isEmpty(locations)) {
      const keys = createCollection({list: locations, key: 'id'});
      return yield put(Actions.syncLocationsSuccess(locations, keys));
    }


    return yield put(Actions.syncLocationsError());
  } catch (error) {
    return yield put(Actions.syncLocationsError());
  }
}

function* syncTicketsSaga(tickets) {
  try {

    if(tickets && !_.isEmpty(tickets)){
      const keys = createCollection({ list: tickets, key: 'id' });

      return yield put(Actions.syncTicketsSuccess(tickets, keys));
    }

    return yield put(Actions.syncTicketsError());
  } catch (error) {
    yield put(Actions.syncTicketsError());
  }
}

function* updateFilterValuesSaga() {
  const filterValues = yield select(getFilter.values);
  const locations = yield select(getLocations.locations);

  const indexedLocations = listFilter({
    content: locations,
    filterKeys: FILTER_KEYS,
    filterValues,
  });

  yield put(Actions.setIndexedLocations(indexedLocations));
  yield call(updateFilterOptionsSaga);
  yield all([
    call(setIndexedCollaboratorsSaga, indexedLocations),
    call(setIndexedTickets, indexedLocations)
  ]);
}

function* updateFilterOptionsSaga() {
  const locations = yield select(getLocations.locations);
  const indexedLocations = yield select(getLocations.indexed);

  if (!_.isEmpty(locations)) {
    const options = getFilterOptions(locations, indexedLocations, FILTER_KEYS);

    if (options) {
      return yield put(Actions.updateFilterOptions(options));
    }
  }
}

function* setIndexedCollaboratorsSaga(indexedLocations = []) {
  const [collaborators, keys] = yield all([
    select(getCollaborators.collaborators),
    select(getCollaborators.keys)
  ]);

  const locationsKeys = yield select(getLocations.keys);

  const actualLocations = _.pickBy(locationsKeys, value =>
    _.includes(indexedLocations, value)
  );

  const keysArray = _.keys(keys);

  const indexedCollaborators = _.filter(keysArray, key => {
    const item = collaborators[keys[key]];

    if (!item || item === undefined || item.locations === undefined)
      return false;

    const result = _.some(
      item.locations,
      location => actualLocations[`${location}`] !== undefined
    );

    return result;
  });

  yield put(Actions.setIndexedCollaborators(indexedCollaborators));
}

function* setIndexedTickets(indexedLocations) {
  const [tickets, keys] = yield all([
    select(getTickets.tickets),
    select(getTickets.keys)
  ]);

  const locationsKeys = yield select(getLocations.keys);

  const keysArray = yield _.keys(keys);

  const actualLocations = _.pickBy(locationsKeys, value =>
    _.includes(indexedLocations, value)
  );

  const indexedTickets = _.filter(keysArray, key => {
    const item = tickets[keys[key]];

    if (!item && !item.location && item.location === undefined) return false;

    const location = actualLocations[item.location];

    return location !== undefined;
  });



  yield put(Actions.setIndexedTickets(indexedTickets));
}

export default function*() {
  return yield all([
    takeLatest(Types.INITIAL_SYNC, initialSync),
    takeLatest(Types.UPDATE_FILTER_VALUES, updateFilterValuesSaga)
  ]);
}
