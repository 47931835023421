// import _ from 'lodash';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    sync: ['franchiseId'],

    syncStores: null,
    syncStoresSuccess: ['stores','storesKeys'],
    syncStoresError: null,

    syncStoreData: ['selectedStore'],
    syncStoreDataSuccess: ['storeData', 'storeScore'],
    syncStoreDataError: null,
    
    syncFormsData: ['selectedTab'],
    syncFormsDataSuccess: ['formsData', 'sectionKeys', 'formsId'],
    syncFormsDataError: null,
    
    selectSection: ['sectionKey'],

    resetForms: null,
  
    sendForms: ['updateData'],
    sendFormsDone: ['sendStatus'],

    updateFilterValues: ['filterValues'],
    updateFilterOptions: ['filterOptions'],
    updateIndexedStores: ['indexedStores'],

    updateSorterValues: ['sorterValues'],

    setSearch: ['search'],
    resetData: null
  },
  { prefix: 'store_sheet' }
);

export { Types };
export default Creators;

const INITIAL_STATE = Immutable({
  franchiseId: null,
  // SECTION stores
  stores: [],
  storesKeys:{},
  indexedStores: [],

  loadingStores: false,

  // SECTION store
  selectedStore: null,
  storeData: {},
  storeScore: {},

  loadingStore: false,

  // SECTION forms
  formsId: null,
  formsData: {},
  selectedTab: 0,
  sectionKeys: [],
  loadingForms: false,
  sendStatus: null,
  sectionKey: '0',

  // SECTION filter
  filterOptions: [],
  filterValues: {},

  sorterValues: {
    quality: {
      group: 'kpi',
      key: 'quality',
      values: ['Melhores primeiro'],
      params: {
        name: 'Melhores primeiro',
        key: 'all',
        rule: '>'
      }
    },
    kpi: {
      group:'kpi',
      key: 'kpi',
      values:['Total'],
      params: {
        key: "total",
        name: "Total",
        rule: ">"
      },
    }
  },

  // SECTION main
  loading: false,
  search: ''
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNC]: (state, { franchiseId }) => ({ ...state, franchiseId }),

  [Types.SYNC_STORES]: state => ({
    ...state,
    loadingStores: true
  }),
  [Types.SYNC_STORES_SUCCESS]: (state, { stores, storesKeys }) => ({
    ...state,
    stores,
    storesKeys,
    loadingStores: false
  }),
  [Types.SYNC_STORES_ERROR]: state => ({
    ...state,
    loadingStores: false
  }),

  [Types.SYNC_STORE_DATA]: (state, { selectedStore }) => ({
    ...state,
    selectedStore,
    loadingStore: true,
    sectionKey:INITIAL_STATE.sectionKey,
    storeScore: INITIAL_STATE.storeScore,
  }),
  [Types.SYNC_STORE_DATA_SUCCESS]: (state, { storeData, storeScore }) => ({
    ...state,
    storeData,
    storeScore,
    loadingStore: false
  }),
  [Types.SYNC_STORE_DATA_ERROR]: state => ({
    ...state,
    loadingStore: false
  }),

  [Types.SYNC_FORMS_DATA]: (state, {selectedTab}) => ({
    ...state,
    selectedTab,
    loadingForms: true
  }),
  [Types.SYNC_FORMS_DATA_SUCCESS]: (state, { formsData, sectionKeys, formsId }) => ({
    ...state,
    loadingForms: false,
    formsData,
    sectionKeys,
    formsId,
  }),
  [Types.SYNC_FORMS_DATA_ERROR]: (state) => ({
    ...state,
    loadingForms: false,
    formsData: INITIAL_STATE.formsData
  }),
  [Types.SEND_FORMS]: (state) => ({
    ...state,
    sendStatus: 'sending',
  }),
  [Types.SEND_FORMS_DONE]: (state,{sendStatus}) => ({
    ...state,
    sendStatus,
  }),

  [Types.SELECT_SECTION]: (state, {sectionKey}) => ({
    ...state,
    sectionKey
  }),

  [Types.RESET_FORMS]: (state) => ({
    ...state,
    loadingForms: INITIAL_STATE.loadingForms,
    formsData: INITIAL_STATE.formsData,
    sectionKeys: INITIAL_STATE.sectionKeys,
    selectedTab: INITIAL_STATE.selectedTab,
    sendStatus: INITIAL_STATE.sendStatus,
    formsId: INITIAL_STATE.formsId
  }),


  [Types.UPDATE_FILTER_VALUES]: (state, { filterValues }) => ({
    ...state,
    filterValues
  }),
  [Types.UPDATE_FILTER_OPTIONS]: (state, { filterOptions }) => ({
    ...state,
    filterOptions
  }),
  [Types.UPDATE_INDEXED_STORES]: (state, { indexedStores }) => ({
    ...state,
    indexedStores
  }),

  [Types.UPDATE_SORTER_VALUES]: (state, { sorterValues }) => ({
    ...state,
    sorterValues
  }),

  [Types.SET_SEARCH]: (state, { search }) => ({
    ...state,
    search
  }),

  [Types.RESET_DATA]: () => INITIAL_STATE
});

const path = 'StoreSheetReducer';

export const getStores = {
  stores: state => state[path].stores,
  indexed: state => state[path].indexedStores,
  loading: state => state[path].loadingStores,
  keys: state => state[path].storesKeys
};

export const getStore = {
  selected: state => state[path].selectedStore,
  data: state => state[path].storeData,
  score: state => state[path].storeScore,
  loading: state => state[path].loadingStore
};

export const getForms = {
  id: state => state[path].formsId,
  data: state => state[path].formsData,
  loading: state => state[path].loadingForms,
  keys: state => state[path].sectionKeys,
  tab: state => state[path].selectedTab,
  sendStatus: state => state[path].sendStatus,
  section: state =>  state[path].sectionKey
};

export const getFilter = {
  options: state => state[path].filterOptions,
  values: state => state[path].filterValues
};

export const getSorter = {
  values: state => state[path].sorterValues
};

export const getMain = {
  franchise: state => state[path].franchiseId,
  loading: state => state[path].loading,
  search: state => state[path].search
};
