import _ from 'lodash';

export const add = ({ list = [], key = '', value = null }) => ({
  ...list,
  [key]: value
});

export const remove = (list, key) =>
  Object.keys(list)
    .filter(_key => _key !== `${key}`)
    .reduce(
      (_obj, _key) => ({
        ..._obj,
        [_key]: list[_key]
      }),
      {}
    );

export const createCollection = ({ list = [], key }) =>
  _.reduce(
    list,
    (prev, curr, index) => ({ ...prev, [curr[`${key}`]]: index }),
    {}
  );

// export const arrayToCollection = ({ list = [] }) =>{
//   return _.assign(
//     {},
//     ...list,
//     _.filter(list, (item, index) => ({
//       [_.chain(item)
//         .keys()
//         .first()
//         .value()]: [
//         _.chain(item)
//           .values()
//           .first()
//           .value()
//       ]
//     }))
//   );

// }

export const inject = (obj, group, key, value) =>
  group === 'general'
    ? { ...obj, [key]: value }
    : { ...obj, [group]: { ...obj[group], [key]: value } };

// const move = {
//   from: {
//     key: '',
//     group: '',
//   },
//   to: {
//     key: '',
//     group: ''
//   }
// }

const flatAssign = (item, key, defaultValue = {}) => {
  const actualItem = item[key];
  if(actualItem === undefined) return defaultValue;
  return _.assign(
    {},
    ...actualItem,
    _.filter(...actualItem, el => ({
      [_.chain(el)
        .keys()
        .first()
        .value()]: [
        _.chain(el)
          .values()
          .first()
          .value()
      ]
    }))
  ) 
}

export const batchNestedCollections = ({collection, key, defaultValue}) => {
  const result = _.map(collection, item => ({
    ...item,
    [key]: flatAssign(item, key, defaultValue)
  }));
  return result;
};
