import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';

const GlobalStyles = createGlobalStyle`
  font-family: ${font('base')};
  background: ${palette('component_background', 0)};

  ::-webkit-scrollbar {
    width: 6px;
    background: ${palette('scrollbar_color', 0)};
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb {
    background: ${palette('primary_color', 0)};
    width: 6px;
  }

  /* .communication-form{
    ::-webkit-scrollbar {
    width: 0px;
    background:transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb {
    background:transparent;
    width: 0px;
  }
  } */

  .ant-picker-panel-container .ant-picker-panels {
    @media(max-width: 768px) {
      display: flex !important;
      flex-direction: column !important;
      flex-wrap: wrap !important;
    }
  }

  .categoriesFilter {
    .ant-popover-inner {
      border-radius: 5px !important;
    }
    .ant-popover-title {
      padding: 5px 10px 4px !important;
    }
    .ant-popover-inner-content {
      padding: 10px !important;
      padding-right: 4px !important;
    }
  }

  .popoverCommunication{
    .ant-popover-inner {
      transform:translateX(-10px)
    }
  }
`;

export default GlobalStyles;
