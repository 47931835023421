import { all, put, takeLatest, select, call } from 'redux-saga/effects';

import listFilter, {getFilterOptions} from '@/helpers/listFilter';
import galleryMock from '@/mocks/gallery.json';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

import { FILTER_KEYS } from '../constants.json';
import Actions, {
  getContent,
  getIndexedItems,
  getFilter,
  getDateFilter,
  Types
} from './reducer';


function* syncContentSaga() {
  try {
    if (process.env.REACT_APP_MOCK === 'true') {
      const { data } = galleryMock;
      yield put(Actions.syncContentSuccess(data));
    } else {
      const dateFilter = yield select(getDateFilter);

      const { data, status } = yield call(api.get, '/gallery', {
        params: {
          startDate: dateFilter.startDate.format('YYYY-MM-DD'),
          endDate: dateFilter.endDate.format('YYYY-MM-DD')
        }
      });

      if (status === 200) {
        return yield put(Actions.syncContentSuccess(data));
      }
    }
  } catch (error) {
    return yield put(Actions.syncContentError());
  }
}

function* filterItemsSaga() {
  const [content, filterValues] = yield all([
    select(getContent),
    select(getFilter.values)
  ]);
  
  const indexedItems = listFilter({
    content,
    filterValues,
    filterKeys: FILTER_KEYS
  });

  yield put(Actions.updateIndexedItems(indexedItems));
  yield call(updateFilterOptionsSaga);
}

function* updateFilterOptionsSaga() {
  const content = yield select(getContent);
  const indexedItems = yield select(getIndexedItems);

  const options = getFilterOptions(content, indexedItems, FILTER_KEYS);

  if (options) {
    return yield put(Actions.updateFilterOptions(options));
  }
}

export default function*() {
  return yield all([
    takeLatest(Types.SYNC_CONTENT, syncContentSaga),
    takeLatest(Types.UPDATE_FILTER_VALUES, filterItemsSaga),
  ]);
}
