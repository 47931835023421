import { message } from 'antd';
import _ from 'lodash';
import { put, all, takeLatest, select, call } from 'redux-saga/effects';

import listFilter, { getFilterOptions } from '@/helpers/listFilter';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

import mockCalls from '../../../mocks/maintenance.json';
import Actions, { Types } from './reducer';

function* fetchCallsSagas(action) {
  const url = `${process.env.REACT_APP_BASE_URL_V3}/workflow/calls`;
  try {
    const { data, status } = yield call(api.get, url);

    const stores = [];

    data &&
      data.data.forEach(store => {
        if (store.type_store === 'SES' || store.type_store === 'KSK')
          stores.push(store);
      });

    const filteredData = {
      ...data,
      data: stores
    };

    if (status === 200) {
      return yield all([
        put(Actions.fetchCallsSuccess(filteredData)),
        call(filterItemsSaga)
      ]);
    }
    return yield put(Actions.fetchCallsError());
  } catch (e) {
    message.error('Erro ao tentar buscar os chamados, tente novamente.', 3);
    yield put(Actions.fetchCallsError());
  }
}

function* filterItemsSaga(action) {
  const data = yield select(state => state.MaintenanceReducer);
  const { calls, filterValues, filterKeys } = data;
  const indexedItems = listFilter({ content: calls, filterKeys, filterValues });
  yield put(Actions.updateIndexedCalls(indexedItems));
  yield call(updateFilterOptionsSaga);
}

function* updateFilterOptionsSaga() {
  const data = yield select(state => state.MaintenanceReducer);
  const { calls, indexedCalls, filterKeys } = data;

  const options = getFilterOptions(calls, indexedCalls, filterKeys);

  if (options) {
    return yield put(Actions.updateFilterOptions(options));
  }
}

function* fetchStoresSagas(action) {
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/stores`;
  const dataReducer = yield select(state => state.MaintenanceReducer);
  const { storesInfo } = dataReducer;
  try {
    const { data, status } = yield call(api.get, url, {
      params: {
        page: storesInfo.page,
        column: storesInfo.orderColumn,
        order_type: storesInfo.orderType,
        filters: {
          locals: {
            search_workflow_stores: [storesInfo.search]
          }
        }
      }
    });
    if (status === 200) {
      return yield put(
        Actions.fetchStoresSuccess(data.stores, data.total_stores)
      );
    }
    return yield put(Actions.fetchStoresError());
  } catch (e) {
    message.error('Erro ao tentar buscar as lojas, tente novamente.', 3);
    yield put(Actions.fetchStoresError());
  }
}

function* fetchDamagesSagas(action) {
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/damages/list_damages`;
  const dataReducer = yield select(state => state.MaintenanceReducer);
  const { channel } = dataReducer;
  try {
    const { data, status } = yield call(api.get, url, {
      params: {
        operation: channel,
        type_reject: 'ORÇAMENTO'
      }
    });
    if (status === 200) {
      const categories = _.uniq(_.map(data, item => item.category));
      return yield put(Actions.fetchDamagesSuccess(categories, data));
    }
    return yield put(Actions.fetchDamagesError());
  } catch (e) {
    message.error('Erro ao tentar buscar as avarias, tente novamente.', 3);
    yield put(Actions.fetchDamagesError());
  }
}

function* createCallSagas(action) {
  const url = `${process.env.REACT_APP_BASE_URL_V2}/workflow/calls`;
  const dataReducer = yield select(state => state.MaintenanceReducer);
  const { callInfos } = dataReducer;
  try {
    const { status } = yield call(api.post, url, callInfos);
    if (status === 200) {
      return yield put(Actions.createCallSuccess());
    }
    return yield put(Actions.createCallError());
  } catch (e) {
    message.error('Erro ao tentar criar o chamado, tente novamente.', 3);
    yield put(Actions.createCallError());
  }
}

export default function*() {
  yield all([
    takeLatest(Types.FETCH_CALLS, fetchCallsSagas),
    takeLatest(Types.UPDATE_FILTER_VALUES, filterItemsSaga),
    takeLatest(Types.FETCH_STORES, fetchStoresSagas),
    takeLatest(Types.FETCH_DAMAGES, fetchDamagesSagas),
    takeLatest(Types.CREATE_CALL, createCallSagas)
  ]);
}
