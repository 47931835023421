import antdEs from 'antd/lib/locale-provider/ca_ES';

import page404 from './page404';
import page500 from './page500';

const esLang = {
  messages: {
    ...page500,
    ...page404,
  },
  antd: antdEs,
  locale: 'es',
};

export default esLang;
