import _ from 'lodash';
import { all, put, takeLatest, select, call } from 'redux-saga/effects';

import listFilter, { getFilterOptions } from '@/helpers/listFilter';
import { batchNestedCollections, createCollection } from '@/helpers/objUpdater';
import kpisMock from '@/mocks/kpis.json';
import storeSheetMock from '@/mocks/store_sheet.json';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

// import {retrieve} from '@/helpers/localStorageAssist';

import { FILTER_KEYS } from '../constants.json';
import Actions, {
  getStore,
  getStores,
  getFilter,
  getForms,
  Types
} from './reducer';

function* syncSaga(action) {
  yield put(Actions.syncStores());
}

function* syncStoresSaga() {
  try {
    let error = true;
    if (process.env.REACT_APP_MOCK === 'true') {
      const { stores } = storeSheetMock;
      const keys = createCollection({ list: stores, key: 'id' });
      error = false;
      yield put(Actions.syncStoresSuccess(stores, keys));
    } else {
      const {
        data: { data },
        status
      } = yield call(api.get, '/store_sheets');
      const stores = batchNestedCollections({ collection: data, key: 'kpi' });
      const keys = createCollection({ list: stores, key: 'id' });

      if (status === 200) {
        error = false;
        return yield put(Actions.syncStoresSuccess(stores, keys));
      }
    }

    if (!error) {
      yield all([call(Actions.setSearch(''))]);
    }

    return yield put(Actions.syncStoresError());
  } catch (error) {
    return yield put(Actions.syncStoresError());
  }
}

function* syncStoreDataSaga() {
  try {
    const selected = yield select(getStore.selected);
    if (selected) {
      if (process.env.REACT_APP_MOCK === 'true') {
        return yield all([
          put(Actions.syncStoreDataSuccess(null, kpisMock)),
          call(updateFilterOptionsSaga)
        ]);
      }
      const { data, status } = yield call(api.get, `/stores/${selected}/kpis`);

      const kpiData = batchNestedCollections({ collection: data, key: 'kpi' });

      if (status === 200) {
        return yield all([
          put(Actions.syncStoreDataSuccess(null, kpiData)),
          call(updateFilterOptionsSaga)
        ]);
      }
    }
    return yield put(Actions.syncStoreDataError());
  } catch (error) {
    return yield put(Actions.syncStoreDataError());
  }
}

function* filterItemsSaga() {
  const [content, filterValues] = yield all([
    select(getStores.stores),
    select(getFilter.values)
  ]);

  const indexedStores = listFilter({
    content,
    filterValues,
    filterKeys: FILTER_KEYS
  });

  yield put(Actions.updateIndexedStores(indexedStores));
  yield call(updateFilterOptionsSaga);
}

function* updateFilterOptionsSaga() {
  const content = yield select(getStores.stores);
  const indexedItems = yield select(getStores.indexed);

  const options = getFilterOptions(content, indexedItems, FILTER_KEYS);

  if (options) {
    return yield put(Actions.updateFilterOptions(options));
  }
}

export function* syncFormsDataSaga(action) {
  try {
    const [selected, stores = []] = yield all([
      select(getStore.selected),
      select(getStores.stores)
      // select(getMain.franchise)
    ]);

    const selectedTab = action.selectedTab
      ? action.selectedTab
      : yield select(getForms.tab);

    if (selected && selectedTab && stores && !!stores.length) {
      if (process.env.REACT_APP_MOCK === 'true') {
        const data = storeSheetMock[selectedTab];

        if (data) {
          const sectionKeys = _.keys(data);
          return yield put(Actions.syncFormsDataSuccess(data, sectionKeys, 0));
        }
      }

      const { data, status } =
        selectedTab !== 'team'
          ? yield call(api.get, `/store_sheets/${selected}/${selectedTab}`)
          : yield call(api.get, `/manager/members`, {
              params: { ses_local_kpi_id: selected }
            });

      const keys = _.chain(data)
        .omit(['id'])
        .keys()
        .value();

      if (status === 200) {
        const finalData =
          selectedTab !== 'team' ? data : { team: data, manager: '' };
        return yield put(
          Actions.syncFormsDataSuccess(finalData, keys, data.id)
        );
      }
    }
    return yield put(Actions.syncFormsDataError());
  } catch (error) {
    return yield put(Actions.syncFormsDataError());
  }
}

function* sendFormsSaga(action) {
  try {
    const { updateData } = action;

    const id = yield select(getForms.id);
    const storeId = yield select(getStore.selected);
    const selectedTab = yield select(getForms.tab);

    if (updateData) {
      if (process.env.REACT_APP_MOCK === 'true') {
        return yield put(Actions.sendFormsDone('done'));
      }

      const url =
        selectedTab !== 'team'
          ? `/store_sheets/${storeId}/${selectedTab}/${id}`
          : `/manager/members/${storeId}`;

      const params =
        selectedTab !== 'team'
          ? updateData
          : { ses_local_kpi_id: storeId, ...updateData };

      const { status } = yield call(api.put, url, params);

      if (status === 200)
        return yield all([
          put(Actions.sendFormsDone('done')),
          put(Actions.syncFormsData(selectedTab))
        ]);
    }
    return yield put(Actions.sendFormsDone('error'));
  } catch (error) {
    return yield put(Actions.sendFormsDone('error'));
  }
}

export default function*() {
  return yield all([
    takeLatest(Types.SYNC, syncSaga),
    takeLatest(Types.SYNC_STORES, syncStoresSaga),
    takeLatest(Types.SYNC_STORE_DATA, syncStoreDataSaga),
    takeLatest(Types.UPDATE_FILTER_VALUES, filterItemsSaga),

    takeLatest(Types.SYNC_FORMS_DATA, syncFormsDataSaga),
    takeLatest(Types.SEND_FORMS, sendFormsSaga)
  ]);
}
