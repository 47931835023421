import _ from 'lodash';
import { put, all, takeLatest, select, call } from 'redux-saga/effects';

import listFilter, {getFilterOptions} from '@/helpers/listFilter';
import {batchNestedCollections} from '@/helpers/objUpdater'
import mockFranchises from '@/mocks/franchises.json';
import mockRanking from '@/mocks/rankings.json';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';


import {FILTER_KEYS, SORTER_KEYS, EXTRA_KEYS} from '../constants.json'
import Actions, { Types } from './reducer'; 

const mock = false;

function* syncUpdateContentSagas(action) {
  try {
    const { contentType } = action;
    if (contentType) {
      let error = false;

      const [locationsData, franchisesData] = yield all([
        yield call(api.get, '/ranking/stores'),
        yield call(api.get, '/ranking/franchises')
      ]);

      if (locationsData.status === 200 && franchisesData.status === 200) {
        const {
          data: { locations, positions }
        } = locationsData;

        const locationsList = batchNestedCollections({
          collection: locations,
          key: 'kpi'
        });

        const positionedLocationsBy = !_.isEmpty(positions)
          ? _.reduce(
              locationsList,
              (prev, curr) => {
                return {
                  equipe: [
                    ...prev.equipe,
                    { ...curr, position: positions.team[curr.id] || null }
                  ],
                  global: [
                    ...prev.global,
                    { ...curr, position: positions.global[curr.id] || null }
                  ]
                };
              },
              { equipe: [], global: [] }
            )
          : null;

        const contentOptions = {
          equipe: positionedLocationsBy.equipe,
          global: positionedLocationsBy.global
        };

        const positionedLocations =
          contentType === 'franchises'
            ? positionedLocationsBy.equipe
            : positionedLocationsBy.global;

        yield put(
          Actions.syncContentSuccess(
            contentType,
            positions ? positionedLocations : locationsList,
            franchisesData.data,
            positions,
            contentOptions
          )
        );
      } else error = true;

      yield put(Actions.syncContentError());
    }

    yield put(Actions.syncContentError());
  } catch (e) {
    yield put(Actions.syncContentError());
  }
}

function* syncContentSagas(action) {
  try {
    const { contentType } = action;
    if (contentType) {
      let error = false;

      if (process.env.REACT_APP_MOCK === 'true' || mock) {
        const { locations, positions } = mockRanking;
        const franchises = mockFranchises;

        const contentOptions = {
          equipe: locations,
          global: locations,
        }
        yield put(
          Actions.syncContentSuccess(contentType, locations, franchises, positions, contentOptions)
        );
      } else {
        const [locationsData, franchisesData] = yield all([
          yield call(api.get, '/ranking/stores'),
          yield call(api.get, '/ranking/franchises')
        ]);


        if (locationsData.status === 200 && franchisesData.status === 200) {
          const { data: { locations, positions } } = locationsData;

          const locationsList = batchNestedCollections({collection: locations, key:'kpi'});

          const positionedLocationsBy = !_.isEmpty(positions)
          ? _.reduce(locationsList, (prev, curr) => 
            {
              return {
                equipe:[...prev.equipe,{...curr, position: positions.team[curr.id] || null}],
                global: [...prev.global, {...curr, position: positions.global[curr.id] || null}],
              }
            }
            ,{equipe:[], global:[]})
          : null;

          const contentOptions = {
            equipe: positionedLocationsBy.equipe,
            global: positionedLocationsBy.global,
          }

          const positionedLocations = (contentType === 'franchises') ? positionedLocationsBy.equipe : positionedLocationsBy.global;

          yield put(
            Actions.syncContentSuccess(
              contentType,
              positions ? positionedLocations : locationsList,
              franchisesData.data,
              positions,
              contentOptions
            )
          );
        } else error = true;
      }

      if (!error) {
        return yield all([
          call(updateFilterKeysSaga, 'locations'),
          call(updateSorterKeysSaga, contentType),
          call(updateExtraKeysSaga),
          put(Actions.setSearchContent('')),
          put(Actions.updateLocalScore()),
        ]);
      }
      yield put(Actions.syncContentError());
    }

    yield put(Actions.syncContentError());
  } catch (e) {
    yield put(Actions.syncContentError());
  }
}

function* updateFilterKeysSaga(contentType) {
  const filterKeys = FILTER_KEYS;
  yield put(Actions.updateFilterKeys(filterKeys));
}

function* updateSorterKeysSaga(contentType) {
  const sorterKeys = SORTER_KEYS[`${contentType}`];
  yield put(Actions.updateSorterKeys(sorterKeys));
}

function* updateExtraKeysSaga(){
  yield put(Actions.updateExtraKeys(EXTRA_KEYS));
}

function* filterItemsSaga(action) {
  const data = yield select(state => state.RankingReducer);
  const { content, filterValues, filterKeys } = data;

  const indexedItems = listFilter({ content, filterKeys, filterValues });

  yield put(Actions.updateIndexedItems(indexedItems));

  yield call(updateFilterOptionsSaga);
}

function* updateFilterOptionsSaga() {
  const data = yield select(state => state.RankingReducer);
  const { content, indexedItems, filterKeys } = data;

  const options = getFilterOptions(content, indexedItems, filterKeys);

    if (options) {
    return yield put(Actions.updateFilterOptions(options));
  }
}

export function* syncLocalScore(action) {
  try {
    const {
      data: { total_score: localScore, kpi: localKpi },
      status
    } = yield call(api.get, '/ranking/general_scores');
    if (status === 200) {
      return yield put(Actions.updateLocalScoreSuccess(localScore, localKpi));
    }
    yield put(Actions.updateLocalScoreError());
  } catch (error) {
    yield put(Actions.updateLocalScoreError());
  }
}

export default function*() {
  yield all([
    takeLatest(Types.SYNC_CONTENT, syncContentSagas),
    takeLatest(Types.UPDATE_FILTER_VALUES, filterItemsSaga),
    takeLatest(Types.UPDATE_LOCAL_SCORE, syncLocalScore),
    takeLatest(Types.UPDATE_CONTENT_TYPE, syncUpdateContentSagas),
  ]);
}
