import React from 'react';
import { IntlProvider } from 'react-intl';

import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';

import themes from '@/config/theme.config';
import AppLocale from '@/locales';

export default function AppProvider({ children }) {
  const locale = 'br';
  const themeName = 'defaultTheme';
  const currentAppLocale = AppLocale[locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
