import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    fetchCalls: null,
    fetchCallsSuccess: ['calls'],
    fetchCallsError: null,
    updateFilterKeys: ['filterKeys'],
    updateFilterValues: ['filterValues'],
    updateFilterOptions: ['filterOptions'],
    updateIndexedCalls: ['indexedCalls'],
    setSearchValue: ['searchValue'],
    resetReducer: null,
    fetchStores: ['page', 'search', 'orderColumn', 'orderType'],
    fetchStoresSuccess: ['stores', 'totalStores'],
    fetchStoresError: null,
    fetchDamages: ['channel'],
    fetchDamagesSuccess: ['categories', 'damages'],
    fetchDamagesError: null,
    filterDamages: ['selectedCategories'],
    createCall: ['callInfos'],
    createCallSuccess: null,
    createCallError: null
  },
  { prefix: 'maintenance/' }
);

export { Types };
export default Creators;

export const INITIAL_STATE = Immutable({
  calls: [],
  indexedCalls: [],
  statuses: [],
  filterValues: [],
  filterOptions: {},
  loadingCalls: false,
  searchValue: '',
  stores: [],
  totalStores: 0,
  isLoadingStores: false,
  storesInfo: {
    page: 1,
    search: '',
    orderColumn: null,
    orderType: null
  },
  channel: null,
  categories: [],
  selectedCategories: null,
  loadingDamages: false,
  damages: [],
  filteredDamages: [],
  callInfos: {},
  loadingCreateCall: false,
  callCreateError: false,

  filterKeys: {
    status: {
      name: 'Status',
      type: 'checkbox',
      group: 'general'
    },
    type_store: {
      name: 'Tipo',
      type: 'checkbox',
      group: 'general'
    }
  }
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_CALLS]: state => ({
    ...state,
    loadingCalls: true
  }),
  [Types.FETCH_CALLS_SUCCESS]: (state, { calls }) => ({
    ...state,
    calls: calls.data,
    statuses: calls.options,
    loadingCalls: false
  }),
  [Types.FETCH_CALLS_ERROR]: state => ({
    ...state,
    loadingCalls: false
  }),
  [Types.FETCH_STORES]: (state, { page, search, orderColumn, orderType }) => ({
    ...state,
    storesInfo: {
      page,
      search,
      orderColumn,
      orderType
    },
    isLoadingStores: true
  }),
  [Types.FETCH_STORES_SUCCESS]: (state, { stores, totalStores }) => ({
    ...state,
    stores,
    totalStores,
    isLoadingStores: false
  }),
  [Types.FETCH_STORES_ERROR]: state => ({
    ...state,
    isLoadingStores: false
  }),
  [Types.FETCH_STORES]: (state, { page, search, orderColumn, orderType }) => ({
    ...state,
    storesInfo: {
      page,
      search,
      orderColumn,
      orderType
    },
    isLoadingStores: true
  }),
  [Types.FETCH_DAMAGES]: (state, { channel }) => ({
    ...state,
    channel,
    loadingDamages: true
  }),
  [Types.FETCH_DAMAGES_SUCCESS]: (state, { categories, damages }) => ({
    ...state,
    categories,
    damages,
    loadingDamages: false
  }),
  [Types.FETCH_DAMAGES_ERROR]: state => ({
    ...state,
    loadingDamages: false
  }),
  [Types.FILTER_DAMAGES]: (state, { selectedCategories }) => ({
    ...state,
    filteredDamages: state.damages.filter(({ category }) => {
      return selectedCategories.includes(category);
    })
  }),
  [Types.UPDATE_FILTER_VALUES]: (state, { filterValues }) => ({
    ...state,
    filterValues
  }),
  [Types.UPDATE_FILTER_OPTIONS]: (state, { filterOptions }) => ({
    ...state,
    filterOptions
  }),
  [Types.UPDATE_INDEXED_CALLS]: (state, { indexedCalls }) => ({
    ...state,
    indexedCalls
  }),
  [Types.SET_SEARCH_VALUE]: (state, { searchValue }) => ({
    ...state,
    searchValue
  }),
  [Types.CREATE_CALL]: (state, { callInfos }) => ({
    ...state,
    callInfos,
    loadingCreateCall: true,
    callCreateError: false
  }),
  [Types.CREATE_CALL_SUCCESS]: state => ({
    ...state,
    loadingCreateCall: false
  }),
  [Types.CREATE_CALL_ERROR]: state => ({
    ...state,
    loadingCreateCall: false,
    callCreateError: true
  }),
  [Types.RESET_REDUCER]: state => ({ ...INITIAL_STATE })
});

export const PATH = 'MaintenanceReducer';

const root = selector => {
  return state => state[PATH][selector];
};

export const calls = {
  getCalls: root('calls'),
  getIndexedCalls: root('indexedCalls'),
  getLoadingCalls: root('loadingCalls'),
  getStatuses: root('statuses')
};

export const filters = {
  getFilterValues: root('filterValues'),
  getFilterOptions: root('filterOptions'),
  getFilterKeys: root('filterKeys')
};

export const search = {
  getSearchValue: root('searchValue')
};

export const stores = {
  getStores: root('stores'),
  getTotalStores: root('totalStores'),
  getLoadingStores: root('isLoadingStores')
};

export const categories = {
  getCategories: root('categories')
};

export const damages = {
  getDamages: root('damages'),
  getLoadingDamages: root('loadingDamages'),
  getFilteredDamages: root('filteredDamages')
};

export const createCallInfos = {
  getLoadingCreateCall: root('loadingCreateCall'),
  getCallCreateError: root('callCreateError')
};
