import React from 'react';

import PublicRoute from '@/routes/PublicRoute';

const Login = React.lazy(() => import('@/auth/Login'));
const Logout = React.lazy(() => import('@/auth/Logout'));
const Register = React.lazy(() => import('@/auth/Register'));


const authRoutes = {
  exact: true,
  path: '/auth',
  name: 'Auth',
  label: 'auth.title',
  key: 'auth',
  component: Login,
  route: PublicRoute,
  restricted: true,
  children: [
      {
          path: '/auth/login',
          label: 'auth.login',
          name: 'Login',
          key: 'login',
          component: Login,
          route: PublicRoute,
          restricted: true,
      },
      {
          path: '/auth/logout',
          label: 'auth.logout',
          name: 'Logout',
          key: 'logout',
          component: Logout,
          route: PublicRoute,
          restricted: true,
      },
      {
          path: '/auth/register',
          label: 'auth.register',
          name: 'Register',
          key: 'register',
          component: Register,
          route: PublicRoute,
          restricted: true,
      },
  ],
};

export default authRoutes;
