import antdUs from 'antd/lib/locale-provider/en_US';

import page404 from './page404';
import page500 from './page500';

const usLang = {
  messages: {
    ...page500,
    ...page404,
  },
  antd: antdUs,
  locale: 'en-US',
};

export default usLang;
