import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  syncScore: ['local'],
  syncScoreSuccess: ['score', 'values', 'local'],
  syncScoreError: null
});

export default Creators;
export { Types };

const INITIAL_STATE = Immutable({
  local: null,
  score: 0,
  values: null,
  loading: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNC_SCORE]: (state) => ({
    ...state,
    loading: true,
  }),
  [Types.SYNC_SCORE_SUCCESS]: (state, { score, values, local }) => ({
    ...state,
    local,
    score,
    values,
    loading: false
  }),
  [Types.SYNC_SCORE_ERROR]: state => ({
    ...state,
    loading: false
  })
});

const path = 'GlobalScoreReducer';

export const get = {
  local: state => state[path].local,
  score: state => state[path].score,
  values: state => state[path].values,
  loading: state => state[path].loading
};

export const sync = local => Creators.syncScore(local);