import _ from 'lodash';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  syncCommunications: null,
  syncCommunicationsSuccess: ['communications'],
  syncCommunicationsError: null,
  setSearchCommunications: ['searchCommunications'],

  setFilterOptions: ['filterOptions'],

  setSelectedCommunicationId: ['selectedCommunicationId'],

  setSelectedAllCheckbox: ['selectedOption'],

  syncStores: null,
  syncStoresSuccess: ['stores'],
  syncStoresError: null,

  setSearchStores: ['searchStores'],
  setIndexedStores: ['indexedStores'],
  setIndexedCommunications: ['indexedCommunications'],

  setSelectedStoreId: ['selectedStoreId'],

  setCommunicationStoreIds: ['storeIds'],

  createCommunicationSuccess: null,
  createCommunicationError: null,
  createCommunication: ['formData'],

  editCommunication: ['formData', 'communicationId', 'fileDeletedIds'],
  editCommunicationSuccess: null,
  editCommunicationError: null,

  deleteCommunicationSuccess: null,
  deleteCommunicationError: null,
  deleteCommunication: ['id'],

  deleteCommunicationFile: ['id'],

  sendFormFinish: null,

  setCheckedStores: ['checkedStores'],

  setIsNewCommunication: ['status'],

  setFormData: ['formData'],

  setSelectedManager: ['selectedManager'],

  setFilterValues: ['filterValues'],

  setCommunicationsStatusFilters: ['communicationsStatusFilters'],

  setFilterStoresValues: ['filterStoresValues'],

  setImagePreview: ['imagePreview'],

  resetState: null,
  clearData: null,

  syncAllRoles: null,
  setAllRoles: ['allRoles'],

  setUsersByRoles: ['usersByRoles'],
  syncUsersByRoles: ['selectedRoles'],
  setSearchUsers: ['searchUsers'],

  setSelectedUserId: ['selectedUserId'],
  setCommunicationUserIds: ['userIds'],
  setIndexedUsers: ['indexedUsers'],

  setFilterUsersValues: ['filterUsersValues'],

  selectType: ['selectedType'],
});

export const INITIAL_STATE = Immutable({
  loadingCommunications: false,
  indexedCommunications: [],
  communications: [],
  searchCommunications: '',
  selectedCommunicationId: null,

  selectedStores: [],

  communicationsStatusFilters: [],

  loadingStores: false,
  indexedStores: [],
  stores: [],
  searchStores: '',
  storesType: 'pending',

  selectedStoreIds: null,

  filterOptions: [],

  selectedManager: '',

  filterValues: {},
  filterStoresValues: {},

  imagePreview: {},

  checkedStores: [],
  selectedAllCheckbox: [],

  loadingSendForm: false,

  showCommunicationFields: false,
  isNewCommunication: false,

  radioStatus: '',

  filterKeys: {
    selected_roles: { name: 'Cargo', group: 'selected_roles', type: 'checkbox' },
    managers: { name: 'Gerente de loja', group: 'managers', type: 'checkbox' },
  },
  formData: {},

  allRoles: [],
  loadingAllRoles: false,

  usersByRoles: [],
  loadingUsersByRoles: false,
  indexedUsers: [],
  searchUsers: '',
  selectedRoles: [],

  selectedUserIds: null,
  filterUsersValues: {},

  communicationType: 'loja'
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SELECTED_MANAGER]: (state, { selectedManager }) => {
    return { ...state, selectedManager };
  },

  [Types.SYNC_COMMUNICATIONS]: state => {
    return { ...state, loadingCommunications: true };
  },
  [Types.SYNC_COMMUNICATIONS_SUCCESS]: (state, { communications }) => {
    return {
      ...state,
      communications,
      loadingCommunications: false,
      indexedCommunications: Array.from(Array(communications.length).keys())
    };
  },
  [Types.SYNC_COMMUNICATIONS_ERROR]: state => {
    return { ...state, loadingCommunications: false };
  },

  [Types.SET_SEARCH_COMMUNICATIONS]: (state, { searchCommunications }) => {
    return { ...state, searchCommunications };
  },
  [Types.SET_SELECTED_COMMUNICATION_ID]: (
    state,
    { selectedCommunicationId }
  ) => {
    return { ...state, selectedCommunicationId };
  },

  [Types.SYNC_STORES]: state => {
    return {
      ...state,
      filterValues: {},
      filterOptions: [],
      loadingStores: true,
      selectedStoreIds: [],
      selectedUserIds: []
    };
  },
  [Types.SYNC_STORES_SUCCESS]: (state, { stores }) => {
    return {
      ...state,
      stores,
      loadingStores: false,
      indexedStores: Array.from(Array(stores.length).keys())
    };
  },
  [Types.SYNC_STORES_ERROR]: state => {
    return { ...state, loadingStores: false };
  },

  [Types.SET_SEARCH_STORES]: (state, { searchStores }) => {
    return { ...state, searchStores };
  },
  [Types.SET_INDEXED_STORES]: (state, { indexedStores }) => {
    return { ...state, indexedStores };
  },
  [Types.SET_INDEXED_COMMUNICATIONS]: (state, { indexedCommunications }) => {
    return { ...state, indexedCommunications };
  },

  [Types.SET_CHECKED_STORES]: (state, { checkedStores }) => {
    return { ...state, checkedStores };
  },

  [Types.SET_SELECTED_STORE_ID]: (state, { selectedStoreId }) => {
    const currentIndex = state.selectedStoreIds.indexOf(selectedStoreId);
    const newChecked = [...state.selectedStoreIds];
    if (currentIndex === -1) {
      newChecked.push(selectedStoreId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    return { ...state, selectedStoreIds: newChecked };
  },

  [Types.SET_COMMUNICATION_STORE_IDS]: (state, { storeIds }) => {
    return { ...state, selectedStoreIds: storeIds };
  },

  [Types.SET_IS_NEW_COMMUNICATION]: (state, { status }) => {
    return { ...state, isNewCommunication: status };
  },
  [Types.SET_FORM_DATA]: (state, { formData }) => {
    return { ...state, formData };
  },

  [Types.CREATE_COMMUNICATION]: state => {
    return { ...state, loadingSendForm: true };
  },
  [Types.CREATE_COMMUNICATION_SUCCESS]: state => {
    return { ...state, loadingSendForm: false };
  },
  [Types.CREATE_COMMUNICATION_ERROR]: state => {
    return { ...state, loadingSendForm: false };
  },

  [Types.EDIT_COMMUNICATION]: state => {
    return { ...state, loadingSendForm: true };
  },
  [Types.EDIT_COMMUNICATION_SUCCESS]: state => {
    return { ...state, loadingSendForm: false };
  },
  [Types.EDIT_COMMUNICATION_ERROR]: state => {
    return { ...state, loadingSendForm: false };
  },

  [Types.DELETE_COMMUNICATION]: state => {
    return { ...state, loadingSendForm: true };
  },

  [Types.DELETE_COMMUNICATION_SUCCESS]: state => {
    return { ...state, loadingSendForm: false };
  },
  [Types.DELETE_COMMUNICATION_ERROR]: state => {
    return { ...state, loadingSendForm: false };
  },

  [Types.DELETE_COMMUNICATION_FILE]: state => {
    return { ...state, loadingSendForm: false };
  },

  [Types.SEND_FORM_FINISH]: state => {
    return { ...state, loadingSendForm: false, isNewCommunication: false };
  },

  [Types.SET_FILTER_VALUES]: (state, { filterValues }) => {
    return { ...state, filterValues: _.pickBy(filterValues) };
  },

  [Types.SET_FILTER_STORES_VALUES]: (state, { filterStoresValues }) => {
    return { ...state, filterStoresValues: _.pickBy(filterStoresValues) };
  },

  [Types.SET_IMAGE_PREVIEW]: (state, { imagePreview }) => {
    return { ...state, imagePreview };
  },

  [Types.SET_FILTER_OPTIONS]: (state, { filterOptions }) => {
    return { ...state, filterOptions };
  },
  [Types.RESET_STATE]: state => ({ ...INITIAL_STATE }),

  [Types.SET_COMMUNICATIONS_STATUS_FILTERS]: (
    state,
    { communicationsStatusFilters }
  ) => ({
    ...state,
    communicationsStatusFilters
  }),
  [Types.SET_SELECTED_ALL_CHECKBOX]: (state, { selectedOption }) => {
    return { ...state, selectedAllCheckbox: selectedOption };
  },
  [Types.CLEAR_DATA]: state => {
    return {
      ...state,
      formData: {},
      selectedStoreIds: [],
      selectedCommunicationId: null,
      selectedStores: [],
      searchStores: '',
      searchUsers: '',
      selectedUserIds: [],
      usersByRoles: [],
      selectedRoles: [],
      communicationType: 'loja',
      indexedUsers: [],
      selectedAllCheckbox: 'all',
    };
  },
  [Types.SYNC_ALL_ROLES]: state => {
    return { ...state, loadingAllRoles: true };
  },
  [Types.SET_ALL_ROLES]: (state, { allRoles }) => {
    return { ...state, allRoles, loadingAllRoles: false };
  },
  [Types.SET_USERS_BY_ROLES]: (state, { usersByRoles }) => {
    return { 
      ...state, 
      usersByRoles, 
      loadingUsersByRoles: false,
      indexedUsers: Array.from(Array(usersByRoles.length).keys())
    }
  }, 
  [Types.SYNC_USERS_BY_ROLES]: (state, { selectedRoles }) => {
    if (_.isEmpty(selectedRoles)){
      return { 
        ...state, 
        loadingUsersByRoles: true, 
        selectedRoles, 
        usersByRoles:[],
        selectedUserIds: [],
        indexedUsers: [],
      }
    }
    return { ...state, loadingUsersByRoles: true, selectedRoles }
  },
  [Types.SET_SEARCH_USERS]: (state, { searchUsers }) => {
    return { ...state, searchUsers }
  },
  [Types.SET_SELECTED_USER_ID]: (state, { selectedUserId }) => {
    const currentIndex = state.selectedUserIds.indexOf(selectedUserId);
    const newChecked = [...state.selectedUserIds];
    if (currentIndex === -1) {
      newChecked.push(selectedUserId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    return { ...state, selectedUserIds: newChecked };
  },
  [Types.SET_COMMUNICATION_USER_IDS]: (state, { userIds }) => {
    return { ...state, selectedUserIds: userIds };
  },
  [Types.SET_FILTER_USERS_VALUES]: (state, { filterUsersValues }) => {
    return { ...state, filterUsersValues: _.pickBy(filterUsersValues) };
  },
  [Types.SET_INDEXED_USERS]: (state, { indexedUsers }) => {
    return { ...state, indexedUsers };
  },
  [Types.SELECT_TYPE]: (state, { selectedType }) => {
    if (selectedType === 'loja') {
      return { 
        ...state, 
        communicationType: selectedType,
        selectedUserIds: [],
      }
    }
    return { 
      ...state, 
      communicationType: selectedType, 
      selectedStoreIds: [],
    }
  },
});

const path = 'CommunicationsReducer';

export { Types };
export default Creators;

export const getCommunications = {
  communications: state => state[path].communications,
  getOneCommunication: (state, index) => state[path].communications[index],
  loadingCommunications: state => state[path].loadingCommunications,
  indexedCommunications: state => state[path].indexedCommunications,
  totalCommunications: state => state[path].indexedCommunications.length,
  emptyCommunications: state => state[path].communications.length <= 0,
  getSelectedCommunicationId: state => state[path].selectedCommunicationId,
  searchCommunicationValues: state => state[path].searchCommunications,
  loadingSendForm: state => state[path].loadingSendForm,
  getIsNewCommunicationStatus: state => state[path].isNewCommunication,
  getFormData: state => state[path].formData,
  getAllRoles: state => state[path].allRoles,
};

export const getStores = {
  stores: state => state[path].stores,
  loadingStores: state => state[path].loadingStores,
  indexedStores: state => state[path].indexedStores,
  emptyStores: state => state[path].stores <= 0,
  getOneStore: (state, index) => state[path].stores[index],
  totalStores: state => state[path].indexedStores.length,
  getSelectedStoreId: state => state[path].selectedStoreIds,
  searchStoresValues: state => state[path].searchStores,
  checkSelectedStore: (state, id) => state[path].selectedStoreIds.includes(id),
  checkedStores: state => state[path].checkedStores,
  getAllStoreIds: state => state[path].stores.map(store => store.id)
};

export const getUsers = {
  getUserByRole: (state, index) => state[path].usersByRoles[index],
  indexedUsers: state => state[path].indexedUsers,
  totalUsers: state => state[path].indexedUsers.length,
  checkSelectedUser: (state, id) => state[path].selectedUserIds.includes(id),
  searchUsersValues: state => state[path].searchUsers,
  getAllUsersIds: state => state[path].usersByRoles.map(user => user.id),
};

export const getfilterOptions = state => state[path].filterOptions;

export const filterOptions = state => state[path].filterOptions;
export const filterValues = state => state[path].filterValues;

export const emptyFilterOptions = state => state[path].filterOptions <= 0;

export const getCommunicationsStatusFilters = state =>
  state[path].communicationsStatusFilters;

export const getImagePreview = state => state[path].imagePreview;

export const getSelectedRoles = state => state[path].selectedRoles;
export const getSelectedType = state => state[path].communicationType;
export const getSelectedUserId = state => state[path].selectedUserIds;

export const getSelectedAllCheckbox = state => state[path].selectedAllCheckbox
