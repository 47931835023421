/* eslint-disable import/no-cycle */
import { all } from 'redux-saga/effects';

import auth from '@/auth/redux';
import workspaces from '@/components/AppLayout/redux';
import globalScore from '@/components/GlobalScore/redux';
import communications from '@/containers/Communication/redux';
import gallery from '@/containers/Gallery/redux';
import maintenanceChat from '@/containers/InfoMaintenance/components/Chat/redux';
import infoMaintenance from '@/containers/InfoMaintenance/redux';
import kpiDashboard from '@/containers/KpiDashboard/redux';
import maintenance from '@/containers/Maintenance/redux';
import MaintenanceConfiguration from '@/containers/MaintenanceConfiguration/redux';
import pricing from '@/containers/Pricing/redux';
import ranking from '@/containers/Ranking/redux';
import storeSheet from '@/containers/StoreSheet/redux';
import tickets from '@/containers/Tickets/redux';
import visitsDashboard from '@/containers/VisitsDashboard/redux';


export default function* rootSaga() {
  yield all([
    auth(),
    workspaces(),
    tickets(),
    ranking(),
    visitsDashboard(),
    kpiDashboard(),
    globalScore(),
    storeSheet(),
    gallery(),
    communications(),
    maintenance(),
    MaintenanceConfiguration(),
    infoMaintenance(),
    maintenanceChat(),
    pricing(),
  ]);
}
