import { all, takeLatest, put, call } from 'redux-saga/effects';

import requestError from '@/helpers/requestError';
import mockWorkspaces from '@/mocks/workplaces.json';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

import Actions, { Types } from './reducer';

export function* syncWorkspacesSagas(action) {

  // Mock Workspaces
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(Actions.syncWorkspacesSuccess(mockWorkspaces.data));
  }

  try{
    const { data: { data }, status} = yield call(api.get, '/workspaces');

    if(status === 200){

      yield put(Actions.syncWorkspacesSuccess(data));

    }else{
      yield put(Actions.syncWorkspacesError());
    }

  }catch(error) {
    requestError(error);
    yield put(Actions.syncWorkspacesError());
  }

};

export default function*() {
  yield all([
    takeLatest(Types.SYNC_WORKSPACES, syncWorkspacesSagas),
  ]);
};
