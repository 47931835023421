/* eslint-disable import/no-cycle */
import { useDispatch } from "react-redux";

import axios from 'axios';

import { Types } from "@/auth/redux/reducer";

import { store } from '../store';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(
  (reqConfig) => {
    const { auth: { token: accessToken } } = store.getState();
    reqConfig.headers.Authorization = `Bearer ${accessToken}`;

    return reqConfig;
  },
  (error) => {
    if(error.response.status === 401 ){
      const dispatch = useDispatch();

      dispatch({type: Types.LOGOUT_USER});
      window.location.pathname = '/';
    }
    return Promise.reject(error);
  },
);


export default api;
