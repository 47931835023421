import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    initialSync: null,
    initialSyncSuccess: null,
    initialSyncError: null,

    syncWeeksSuccess: ['weeks', 'weeksKeys'],
    syncWeeksError: null,

    setIndexedCollaborators: ['indexedCollaborators'],

    syncLocationsSuccess: ['locations', 'locationsKeys'],
    syncLocationsError: null,
    setIndexedLocations: ['indexedLocations'],

    // syncTickets: ['tickets'],
    syncTicketsSuccess: ['tickets', 'ticketsKeys'],
    syncTicketsError: null,
    setIndexedTickets: ['indexedTickets'],

    // syncFranchises: ['franchises'],
    syncFranchisesSuccess: ['franchises','franchisesKeys'],
    syncFranchisesError: null,

    // syncVisits: ['visits'],
    syncVisitsSuccess: ['collaborators','collaboratorsKeys', 'cycles'],
    syncVisitsError: null,

    updateFilterValues: ['filterValues'],
    updateFilterOptions: ['filterOptions'],

    updateSearch: ['search'],
    resetData: null
  },
  { prefix: 'visits_dashboard' }
);

export { Types };
export default Creators;

const INITIAL_STATE = Immutable({
  weeks: [],
  weeksKeys:{},
  loadingWeeks: false,

  collaborators: [],
  collaboratorsKeys: {}, // NOTE [{[id]:index}]
  indexedCollaborators: [], // NOTE [index]
  loadingCollaborators: true,

  locations: [],
  locationsKeys: {},
  indexedLocations: [],
  loadingLocations: true,

  tickets: [],
  ticketsKeys: {},
  indexedTickets: [],
  loadingTickets: true,

  franchises:[],
  franchisesKeys:{},
  loadingFranchises: true,

  loadingVisits: true,

  cycles: {},

  filterValues: {},
  filterOptions: {},

  search: '',

  loading: false
});

const INITIAL_SYNC = (state, loading = false) => ({
  ...state,
  loading,
  loadingCollaborators: loading,
  loadingLocations: loading,
  loadingTickets: loading,
  loadingWeeks: loading,
  loadingVisits: loading
})

export const reducer = createReducer(INITIAL_STATE, {
  [Types.INITIAL_SYNC]: state => INITIAL_SYNC(state, true),
  [Types.INITIAL_SYNC_SUCCESS]: state => INITIAL_SYNC(state, false),
  [Types.INITIAL_SYNC_ERROR]: state => INITIAL_SYNC(state, false),
  
  // SECTION WEEKS
  [Types.SYNC_WEEKS_SUCCESS]: (state, { weeks, weeksKeys }) => ({
    ...state,
    weeks,
    weeksKeys,
    loadingWeeks: false
  }),
  [Types.SYNC_WEEKS_ERROR]: state => ({
    ...state,
    loadingWeeks: false
  }),

  // SECTION COLLABORATORS
  [Types.SET_INDEXED_COLLABORATORS]: (state, { indexedCollaborators }) => ({
    ...state,
    indexedCollaborators,
    loadingCollaborators: false
  }),

  // SECTION LOCATIONS
  [Types.SYNC_LOCATIONS_SUCCESS]: (state, { locations, locationsKeys }) => ({
    ...state,
    locations,
    locationsKeys,
    loadingLocations: false
  }),
  [Types.SYNC_LOCATIONS_ERROR]: state => ({
    ...state,
    loadingLocations: false
  }),

  [Types.SET_INDEXED_LOCATIONS]: (state, { indexedLocations }) => ({
    ...state,
    indexedLocations
  }),

  // SECTION TICKETS
  [Types.SYNC_TICKETS_SUCCESS]: (state, { tickets, ticketsKeys }) => ({
    ...state,
    tickets,
    ticketsKeys,
    loadingTickets: false
  }),
  [Types.SYNC_TICKETS_ERROR]: state => ({
    ...state,
    loadingTickets: false
  }),
  [Types.SET_INDEXED_TICKETS]: (state, { indexedTickets }) => ({
    ...state,
    indexedTickets
  }),

  // SECTION FRANCHISES
  [Types.SYNC_FRANCHISES_SUCCESS]: (state, {franchises, franchisesKeys}) => ({
    ...state,
    franchises,
    franchisesKeys,
    loadingFranchises: false,
  }),
  [Types.SYNC_FRANCHISES_ERROR]: state => ({
    ...state,
    loadingFranchises: false,
  }),

  // SECTION VISITS
  [Types.SYNC_VISITS_SUCCESS]: (state, {collaborators, collaboratorsKeys, cycles}) => ({
    ...state,
    collaborators,
    collaboratorsKeys,
    cycles,
    loadingVisits: false
  }),
  [Types.SYNC_VISITS_ERROR]: state => ({
    ...state,
    loadingVisits: false
  }),

  // SECTION FILTER
  [Types.UPDATE_FILTER_OPTIONS]: (state, { filterOptions }) => ({
    ...state,
    filterOptions
  }),
  [Types.UPDATE_FILTER_VALUES]: (state, { filterValues }) => ({
    ...state,
    filterValues
  }),

  // SECTION MAIN
  [Types.UPDATE_SEARCH]: (state, { search }) => ({
    ...state,
    search
  }),
  [Types.RESET_DATA]: () => INITIAL_STATE
});

const path = 'VisitsDashboardReducer';

export const getWeeks = {
  weeks: state => state[path].weeks,
  keys: state => state[path].weeksKeys,
  loading: state => state[path].loadingWeeks
};

export const getFranchises = {
  franchises: state => state[path].franchises,
  keys: state => state[path].franchisesKeys,
  loading: state => state[path].loadingFranchises
}

export const getCollaborators = {
  collaborators: state => state[path].collaborators,
  keys: state => state[path].collaboratorsKeys,
  loading: state => state[path].loadingCollaborators,
  indexed: state => state[path].indexedCollaborators
};

export const getLocations = {
  locations: state => state[path].locations,
  keys: state => state[path].locationsKeys,
  indexed: state => state[path].indexedLocations,
  loading: state => state[path].loadingLocations
};

export const getTickets = {
  tickets: state => state[path].tickets,
  keys: state => state[path].ticketsKeys,
  indexed: state => state[path].indexedTickets,
  loading: state => state[path].loadingTickets
};

export const getFilter = {
  values: state => state[path].filterValues,
  keys: state => state[path].filterKeys,
  options: state => state[path].filterOptions
};

export const getMain = {
  search: state => state[path].search,
  loading: state => state[path].loading
};

export const getCycles = {
  cycles: state => state[path].cycles
}
