import { combineReducers } from 'redux';

import { reducer as auth } from '@/auth/redux/reducer';
import { reducer as WorkspacesReducer } from '@/components/AppLayout/redux/reducer';
import { reducer as GlobalScoreReducer } from '@/components/GlobalScore/redux/reducer';
import { reducer as CommunicationsReducer } from '@/containers/Communication/redux/reducer';
import { reducer as GalleryReducer } from '@/containers/Gallery/redux/reducer';
import { reducer as MaintenanceChatReducer } from '@/containers/InfoMaintenance/components/Chat/redux/reducer';
import { reducer as InfoMaintenanceReducer} from '@/containers/InfoMaintenance/redux/reducer';
import { reducer as KpisDashboardReducer } from '@/containers/KpiDashboard/redux/reducer';
import { reducer as MaintenanceReducer } from '@/containers/Maintenance/redux/reducer';
import {reducer as MaintenanceConfigurationReducer} from '@/containers/MaintenanceConfiguration/redux/reducer';
import { reducer as PricingReducer } from '@/containers/Pricing/redux/reducer';
import { reducer as RankingReducer } from '@/containers/Ranking/redux/reducer';
import { reducer as StoreSheetReducer } from '@/containers/StoreSheet/redux/reducer';
import { reducer as TicketsReducer } from '@/containers/Tickets/redux/reducer';
import { reducer as VisitsDashboardReducer } from '@/containers/VisitsDashboard/redux/reducer';

export default combineReducers({
  auth,
  WorkspacesReducer,
  TicketsReducer,
  RankingReducer,
  VisitsDashboardReducer,
  KpisDashboardReducer,
  GlobalScoreReducer,
  StoreSheetReducer,
  GalleryReducer,
  CommunicationsReducer,
  MaintenanceReducer,
  MaintenanceConfigurationReducer,
  InfoMaintenanceReducer,
  MaintenanceChatReducer,
  PricingReducer
});
