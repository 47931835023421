import { notification } from 'antd';

const codeMessage = {
  200: 'O servidor retornou com sucesso os dados solicitados.',
  201: 'Dados novos ou modificados foram bem-sucedidos.',
  202: 'Uma solicitação foi colocada na fila em segundo plano (tarefa assíncrona).',
  204: 'Dados excluídos com sucesso.',
  400: 'A solicitação enviada possui um erro e o servidor não criou ou modificou dados.',
  401: 'O usuário não tem permissão (token, nome de usuário, senha incorreta).',
  403: 'O usuário está autorizado, mas o acesso é proibido.',
  404: 'A solicitação foi feita para um registro que não existe e o servidor não fez nada.',
  406: 'O formato solicitado não está disponível.',
  410: 'O recurso solicitado foi excluído permanentemente e não estará mais disponível.',
  422: 'Ao criar um objeto, ocorreu um erro de validação.',
  500: 'Ocorreu um erro no servidor. Verifique o servidor.',
  502: 'Erro no gateway.',
  503: 'O serviço está indisponível, o servidor está temporariamente sobrecarregado ou mantido.',
  504: 'O tempo limite do gateway expirou.',
};

const requestError = error => {
  const { response } = error;

  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    notification.error({
      message: `Erro de solicitação ${status}: ${url}`,
      description: errorText,
    });
  } else if (!response) {
    notification.error({
      description: 'Sua rede está anormal e não pode se conectar ao servidor',
      message: 'Exceção de rede',
    });
  }

  return response;
};

export default requestError;
