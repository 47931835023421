import React from 'react';

import { Tooltip, message } from 'antd';
import _ from 'lodash';
import { all, put, takeLatest, call, select } from 'redux-saga/effects';

import requestError from '@/helpers/requestError';
// eslint-disable-next-line import/no-cycle
import mock from '@/mocks/pricing.json';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

import fieldsOptions from '../../../mocks/selectsPricingOptions.json';
import PricingActions, { Types, getFilter, getPricing } from './reducer';

export function* syncPricingsSagas(action) {
  if (process.env.REACT_APP_MOCK === 'true') {
    yield put(PricingActions.syncPricingsSuccess(mock.pricings));
    return yield call(updateFilterValuesPricing, action);
  }
  try {
    const dates = yield select(getFilter.filterDates);

    const { data } = yield call(api.get, '/pricings', {
      params: { date: dates }
    });

    yield put(PricingActions.syncPricingsSuccess(data.pricings));
    yield call(updateFilterValuesPricing, action);
  } catch (error) {
    requestError(error);
    yield put(PricingActions.syncPricingsError());
  }
}

export function* fetchCurrentPricing({ id }) {
  if (process.env.REACT_APP_MOCK === 'true')
    return yield put(PricingActions.fetchCurrentPricingSuccess(mock.pricing));

  try {
    const { data } = yield call(api.get, `/pricings/${id}`);

    yield put(PricingActions.fetchCurrentPricingSuccess(data));
  } catch (error) {
    requestError(error);
    yield put(PricingActions.fetchCurrentPricingError());
  }
}

export function* createPricing({ formData }) {
  // Mock Send Form
  if (process.env.REACT_APP_MOCK === 'true')
    return yield put(PricingActions.createPricingSuccess());

  try {
    const files = formData.model_url
      ? formData.model_url.map(file => ({
          name: file.name,
          url: file.response.url
        }))
      : null;

    yield call(api.post, `/pricings`, {
      ...formData,
      model_url: files
    });

    yield put(PricingActions.sendFormFinish());
    yield put(PricingActions.clearData());

    message.success('Precificador criado com sucesso.');
  } catch (error) {
    requestError(error);
    yield put(PricingActions.sendFormFinish());
  }
}

export function* editPricing({ formData }) {
  // Mock Send Form
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(PricingActions.editPricingSuccess());
  }

  try {
    const { id } = yield select(getPricing.getCurrentPricing);

    const responseFile = formData.model_url
      ? formData.model_url[0].response
      : null;
    const files = formData.model_url
      ? formData.model_url.map(file => ({
          name: file.name,
          url: responseFile ? file.response.url : file.url
        }))
      : null;

    if (files && formData.model_url[0].create) files[0].create = true;

    formData.model_url = files;
    formData.pricing_type = formData.type;
    delete formData.type;

    yield call(api.patch, `/pricings/${id}`, {
      ...formData
    });

    yield put(PricingActions.sendFormFinish());
    yield put(PricingActions.clearData());

    message.success('Precificador editado com sucesso.');
  } catch (error) {
    requestError(error);
    yield put(PricingActions.sendFormFinish());
  }
}

export function* deletePricing(action) {
  const { id } = action;
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(PricingActions.deletePricingSuccess());
  }

  try {
    yield call(api.delete, `/pricings/${id}`);

    yield put(PricingActions.sendFormFinish());
    yield put(PricingActions.clearData());

    message.success('Precificador excluído com sucesso.');
  } catch (error) {
    requestError(error);
    yield put(PricingActions.sendFormFinish());
  }
}

export function* deletePricingFile(action) {
  const { id } = action;
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(PricingActions.deletePricingSuccess());
  }

  try {
    yield call(api.delete, `/pricing_files/${id}`);
  } catch (error) {
    requestError(error);
  }
}

export function* updatePricingInfo() {
  yield put(PricingActions.syncPricings());
}

export function* filterPricing(action) {
  const data = yield select(state => state.PricingReducer);

  const pricings = Array.from(Array(data.pricings.length).keys());

  if (
    !data.searchPricing &&
    _.isEmpty(data.filterValues) &&
    _.isEmpty(data.statusFilters)
  ) {
    return yield put(PricingActions.setIndexedPricing(pricings));
  }

  const indexedPricing = [];

  pricings.forEach(pricingIndex => {
    let validate = true;

    const pricing = data.pricings[pricingIndex];

    if (
      data.searchPricing &&
      !pricing.name.toLowerCase().includes(data.searchPricing.toLowerCase())
    )
      validate = false;

    if (!_.isEmpty(data.filterValues)) {
      Object.entries(data.filterValues).forEach(([filter, values]) => {
        if (
          data.filterKeys[filter].group === 'general' &&
          !values.includes(pricing[filter])
        )
          validate = false;
        else if (
          data.filterKeys[filter].group === 'category' &&
          _.isEmpty(
            values.filter(value => pricing.category.name.includes(value))
          )
        )
          validate = false;
      });
    }

    if (
      !_.isEmpty(data.statusFilters) &&
      _.isEmpty(
        data.statusFilters.filter(
          status => pricing?.status === (status ? 'enabled' : 'disabled')
        )
      )
    ) {
      validate = false;
    }

    if (validate) indexedPricing.push(pricingIndex);
  });

  yield put(PricingActions.setIndexedPricing(indexedPricing));
}

export function* updateFilterValuesPricing(action) {
  const data = yield select(state => state.PricingReducer);

  if (!data.pricings.length > 0)
    return yield put(PricingActions.setFilterOptions([]));

  const pricings = [];

  data.indexedPricing.forEach(pricingIndex => {
    pricings.push(data.pricings[pricingIndex]);
  });

  const compare = (a, b) => {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  };

  const tooltipComponent = (text, count) => {
    if (text.length <= 20) return `${text} (${count})`;

    return (
      <Tooltip title={text} placement="right">
        {`${text.slice(0, 20)}... (${count})`}
      </Tooltip>
    );
  };

  const filters = Object.entries(data.filterKeys).map(
    ([key, filter], index) => {
      let countKey = {};

      if (filter.group === 'general') {
        countKey = _.countBy(pricings, key);
      } else if (filter.group === 'category') {
        countKey = _.countBy(pricings, 'category.name');
      } else {
        countKey = _.countBy(pricings, princing => princing[filter.group][key]);
      }

      if (Object.keys(countKey).filter(i => i !== 'undefined') <= 0)
        return null;

      const currentFilters = Object.keys(data.filterValues);

      if (
        currentFilters.length > 0 &&
        currentFilters.includes(key) &&
        data.filterOptions[index]
      ) {
        const { items } = data.filterOptions[index];
        Object.keys(items).forEach(value => {
          if (!(value in countKey)) countKey[value] = items[value];
        });
      }

      return {
        key,
        items: countKey,
        name: filter.name,
        type: filter.type,
        options: _.compact(Object.keys(countKey))
          .map(value => ({
            label: tooltipComponent(value, countKey[value]),
            value
          }))
          .sort(compare)
      };
    }
  );

  yield put(PricingActions.setFilterOptions(filters));
}

export function* fetchFieldsOptions() {
  if (process.env.REACT_APP_MOCK === 'true')
    return yield put(PricingActions.fetchFieldsOptionsSuccess(fieldsOptions));

  try {
    const { data } = yield call(api.get, `/pricings/preset_options`);

    yield put(PricingActions.fetchFieldsOptionsSuccess(data));
  } catch (error) {
    requestError(error);
    yield put(PricingActions.fetchFieldsOptionsError());
  }
}

export default function*() {
  yield all([
    takeLatest(Types.SYNC_PRICINGS, syncPricingsSagas),
    takeLatest(Types.SET_INDEXED_PRICING, updateFilterValuesPricing),
    takeLatest(Types.FETCH_CURRENT_PRICING, fetchCurrentPricing),
    takeLatest(Types.FETCH_FIELDS_OPTIONS, fetchFieldsOptions),
    takeLatest(Types.CREATE_PRICING, createPricing),
    takeLatest(Types.EDIT_PRICING, editPricing),
    takeLatest(Types.DELETE_PRICING, deletePricing),
    takeLatest(Types.SET_PRICING_FILTER_VALUES, filterPricing),
    takeLatest(Types.SEND_FORM_FINISH, updatePricingInfo),
    takeLatest(Types.SET_SEARCH_PRICING, filterPricing)
  ]);
}
