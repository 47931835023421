/* eslint-disable import/no-cycle */
import React from 'react';

import { Tooltip } from 'antd';
import _ from 'lodash';
import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import { getRole, getUserData } from '@/auth/redux/reducer';
import { batchNestedCollections, createCollection } from '@/helpers/objUpdater';
import requestError from '@/helpers/requestError';
import mockKpis from '@/mocks/kpis.json';
import mockStores from '@/mocks/stores.json';
import mockTicketHistory from '@/mocks/ticket_history.json';
import mockTicket from '@/mocks/ticket.json';
import mockTickets from '@/mocks/tickets.json';
import api from '@/services/api';

import Actions, { Types, getTicket } from './reducer';

export function* syncStoresSagas(action) {
  // Mock Stores
  if (process.env.REACT_APP_MOCK === 'true') {
    yield put(Actions.syncStoresSuccess(mockStores.data));
    return yield call(updateFilterValuesStores, action);
  }

  try {
    const storesType = yield select(state => state.TicketsReducer.storesType);

    const {
      data: { data },
      status
    } = yield call(api.get, '/stores', { params: { type: storesType } });
    if (status === 200) {
      yield put(Actions.syncStoresSuccess(data));

      const initialSelected = _.first(data);

      if (data.length > 0) {
        yield call(updateFilterValuesStores, action);
        if (initialSelected)
          yield put(Actions.setSelectedStoreId(initialSelected.id));
      }
    } else {
      yield put(Actions.syncStoresError());
    }
  } catch (error) {
    requestError(error);
    yield put(Actions.syncStoresError());
  }
}

export function* filterStores(action) {
  const data = yield select(state => state.TicketsReducer);
  const statusesFilters = data.ticketStatusFilters;

  const stores = Array.from(Array(data.stores.length).keys());

  if (
    !data.searchStores &&
    _.isEmpty(data.filterValues) &&
    _.isEmpty(statusesFilters)
  ) {
    return yield put(Actions.setIndexedStores(stores));
  }

  const indexedStores = [];

  stores.forEach(storeIndex => {
    let validate = true;

    const store = data.stores[storeIndex];

    if (
      data.searchStores &&
      !store.name.toLowerCase().includes(data.searchStores.toLowerCase())
    )
      validate = false;

    if (!_.isEmpty(data.filterValues)) {
      Object.entries(data.filterValues).forEach(([filter, values]) => {
        if (
          data.filterKeys[filter].group === 'general' &&
          !values.includes(store[filter])
        )
          validate = false;
        else if (
          data.filterKeys[filter].group === 'calls_categories' &&
          !_.isEmpty(statusesFilters) &&
          _.isEmpty(
            values.filter(
              callItem =>
                !_.isEmpty(
                  store.calls_categories_statuses?.infos.filter(
                    item =>
                      item[callItem] &&
                      !_.isEmpty(
                        statusesFilters.filter(status =>
                          item[callItem]?.includes(status)
                        )
                      )
                  )
                )
            )
          )
        )
          validate = false;
        else if (
          data.filterKeys[filter].group === 'calls_kpis' &&
          !_.isEmpty(statusesFilters) &&
          _.isEmpty(
            values.filter(
              kpi =>
                !_.isEmpty(
                  store.calls_kpis_statuses?.infos.filter(
                    item =>
                      item[kpi] &&
                      !_.isEmpty(
                        statusesFilters.filter(status =>
                          item[kpi]?.includes(status)
                        )
                      )
                  )
                )
            )
          )
        )
          validate = false;
        else if (
          data.filterKeys[filter].group === 'calls_categories' &&
          _.isEmpty(
            values.filter(
              callItem =>
                !_.isEmpty(
                  store.calls_categories_statuses?.infos.filter(
                    item => item[callItem]
                  )
                )
            )
          )
        )
          validate = false;
        else if (
          data.filterKeys[filter].group === 'calls_kpis' &&
          _.isEmpty(
            values.filter(
              kpi =>
                !_.isEmpty(
                  store.calls_kpis_statuses?.infos.filter(
                    item => item[kpi]
                  )
                )
            )
          )
        )
          validate = false;
        else if (
          data.filterKeys[filter].group !== 'general' &&
          data.filterKeys[filter].group !== 'calls_categories' &&
          data.filterKeys[filter].group !== 'calls_kpis' &&
          !values.includes(store[data.filterKeys[filter].group][filter])
        )
          validate = false;
      });
    }

    if (
      !_.isEmpty(statusesFilters) &&
      _.isEmpty(
        statusesFilters.filter(status => store?.calls_statuses.includes(status))
      )
    ) {
      validate = false;
    }

    if (validate) indexedStores.push(storeIndex);
  });

  yield put(Actions.setIndexedStores(indexedStores));
}

export function* updateFilterValuesStores(action) {
  const data = yield select(state => state.TicketsReducer);
  const statuses = data.ticketStatusFilters;
  const selectedCategories = data.filterValues?.calls_categories;
  const selectedKpis = data.filterValues?.calls_kpis;

  const stores = [];

  data.indexedStores.forEach(storeIndex => {
    stores.push(data.stores[storeIndex]);
  });

  const compare = (a, b) => {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  };

  const tooltipComponent = (text, count) => {
    if (text.length <= 11) return `${text} (${count})`;

    return (
      <Tooltip title={text} placement="right">
        {`${text.slice(0, 11)}... (${count})`}
      </Tooltip>
    );
  };

  const filters = Object.entries(data.filterKeys).map(
    ([key, filter], index) => {
      let countKey = {};
      let callsItems = [];
      let kpis = [];

      if (filter.group === 'general') {
        countKey = _.countBy(stores, key);
      } else if (filter.group === 'calls_categories') {
        stores.forEach(store => {
          if (selectedKpis) {
            selectedKpis.forEach(kpi => {
              store.calls_kpis_categories.infos.forEach(value => {
                const items = value[kpi]

                if (!_.isEmpty(items)) {
                  callsItems = callsItems.concat(items)
                }
              })
            })
          } else if (!_.isEmpty(statuses)) {
            statuses.forEach(status => {
              const itemsFiltered = store.calls_categories_statuses?.infos.filter(
                item => Object.values(item)[0].includes(status)
              );
              callsItems = callsItems.concat(
                itemsFiltered.map(item => Object.keys(item))
              );
            });
          }  else if (!_.isEmpty(store.calls_categories_statuses)) {
              callsItems = callsItems.concat(
                store.calls_categories_statuses?.infos.map(item => Object.keys(item))
              );
          }
        });
        countKey = _.countBy(callsItems);
      } else if (filter.group === 'calls_kpis') {
        stores.forEach(store => {
          if (selectedCategories) {
            selectedCategories.forEach(category => {
              store.calls_kpis_categories.infos.forEach(item => {
                const hasCategories = Object.values(item)[0].includes(category);

                if (hasCategories) {
                  kpis = kpis.concat(Object.keys(item))
                }
              })
            })
          } else if (!_.isEmpty(statuses)) {
            statuses.forEach(status => {
              const itemsFiltered = store.calls_kpis_statuses?.infos.filter(
                item => Object.values(item)[0].includes(status)
              );
              kpis = kpis.concat(itemsFiltered.map(item => Object.keys(item)));
            });
          } else if (!_.isEmpty(store.calls_kpis_statuses)){
            kpis = kpis.concat(
              store.calls_kpis_statuses?.infos.map(item =>
                Object.keys(item)
              )
            );
          }
        });
        countKey = _.countBy(kpis);
      } else {
        countKey = _.countBy(stores, store => store[filter.group][key]);
      }
      if (Object.keys(countKey).filter(i => i !== 'undefined') <= 0)
        return null;

      const currentFilters = Object.keys(data.filterValues);

      if (
        currentFilters.length > 0 &&
        currentFilters.includes(key) &&
        data.filterOptions[index]
      ) {
        const { items } = data.filterOptions[index];
        Object.keys(items).forEach(value => {
          if (!(value in countKey)) countKey[value] = items[value];
        });
      }

      return {
        key,
        items: countKey,
        name: filter.name,
        type: filter.type,
        options: _.compact(Object.keys(countKey))
          .map(value => ({
            label: tooltipComponent(value, countKey[value]),
            value
          }))
          .sort(compare)
      };
    }
  );

  yield put(Actions.setFilterOptions(filters));
}

export function* updateStores(action) {
  yield put(Actions.syncStores());
}

export function* syncTicketsSagas() {
  // Mock Tickets
  if (process.env.REACT_APP_MOCK === 'true') {
    const ticketsKeys = createCollection({ list: mockTickets.data, key: 'id' });
    return yield put(
      Actions.syncTicketsSuccess(
        mockTickets.data,
        mockTickets.options,
        mockKpis,
        ticketsKeys
      )
    );
  }

  try {
    const selectedStoreId = yield select(
      state => state.TicketsReducer.selectedStoreId
    );

    const [tickets, kpis] = yield all([
      call(api.get, '/tickets', { params: { store_id: selectedStoreId } }),
      call(api.get, `/stores/${selectedStoreId}/kpis`)
    ]);

    const kpisData = (kpis.data.length && kpis.data[0] === null) ? [] : kpis.data;

    const kpiData = _.first(
      batchNestedCollections({ collection: kpisData, key: 'kpi' })
    );

    const ticketsKeys = createCollection({
      list: tickets.data.data,
      key: 'id'
    });

    if (tickets.status === 200 /* && kpis.status === 200 */) {
      yield put(
        Actions.syncTicketsSuccess(
          tickets.data.data,
          tickets.data.options,
          kpiData,
          ticketsKeys
          // kpis.data,
        )
      );
    } else {
      yield put(Actions.syncTicketsError());
    }
  } catch (error) {
    requestError(error);
    yield put(Actions.syncTicketsError());
  }
}

export function* updateTickets(action) {
  yield put(Actions.syncTickets());
}

export function* syncTicketHistorySagas(action) {
  // Mock Ticket History
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(
      Actions.syncTicketHistorySuccess(
        mockTicketHistory.data,
        mockTicket.ticket,
        mockTicket.partner_return
      )
    );
  }

  try {
    const selectedTicketId = yield select(
      state => state.TicketsReducer.selectedTicketId
    );

    const [history, info] = yield all([
      call(api.get, `/tickets/${selectedTicketId}/kpi_call_activities`),
      call(api.get, `/tickets/${selectedTicketId}/ticket_infos`)
    ]);

    if (history.status === 200 && info.status === 200) {
      yield put(
        Actions.syncTicketHistorySuccess(
          history.data.data,
          info.data.ticket,
          info.data.partner_return,
          info.data.ssg_feedback,   
          // status,
        )
      );
    } else {
      yield put(Actions.syncTicketHistoryError());
    }
  } catch (error) {
    requestError(error);
    yield put(Actions.syncTicketHistoryError());
  }
}

export function* updateTicketHistory(action) {
  yield put(Actions.syncTicketHistory());
}

export function* sendForm(action) {
  const { formData, successCallback } = action;

  // Mock Send Form
  if (process.env.REACT_APP_MOCK === 'true')
    return yield put(Actions.sendFormFinish());

  try {
    const role = yield select(getRole);
    const { id: userId } = yield select(getUserData);
    const {
      info: { ticket_id: ticketId }
    } = yield select(getTicket);
    const allowance = formData.allowance ? '/allowances' : '/cancel_allowance';

    yield call(
      api.put,
      `/tickets/${ticketId}${
        Object.keys(formData).includes('allowance') ? allowance : ''
      }`,
      {
        ...formData,
        user_id: userId,
        role
      }
    );

    yield put(Actions.sendFormFinish());

    successCallback();
  } catch (error) {
    requestError(error);
    yield put(Actions.sendFormFinish());
  }
}

export function* updateTickeInfos() {
  yield all([
    call(syncTicketsSagas),
    // put(Actions.syncTickets()),
    call(syncTicketHistorySagas)
    // put(Actions.syncTicketHistory()),
  ]);
}

export default function*() {
  yield all([
    takeLatest(Types.SYNC_STORES, syncStoresSagas),
    takeLatest(Types.SET_STORES_TYPE, updateStores),
    takeLatest(Types.SET_SEARCH_STORES, filterStores),
    takeLatest(Types.SET_FILTER_VALUES, filterStores),
    takeLatest(Types.SET_INDEXED_STORES, updateFilterValuesStores),

    takeLatest(Types.SYNC_TICKETS, syncTicketsSagas),
    takeLatest(Types.SET_SELECTED_STORE_ID, updateTickets),

    takeLatest(Types.SYNC_TICKET_HISTORY, syncTicketHistorySagas),
    takeLatest(Types.SET_SELECTED_TICKET_ID, updateTicketHistory),

    takeLatest(Types.SEND_FORM, sendForm),
    takeLatest(Types.SEND_FORM_FINISH, updateTickeInfos)
  ]);
}
