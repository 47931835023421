import _ from 'lodash';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions(
  {
    syncStores: null,
    syncStoresSuccess: ['stores'],
    syncStoresError: null,

    setStoresType: ['storesType'],

    setSearchStores: ['searchStores'],
    setIndexedStores: ['indexedStores'],

    setSelectedStoreId: ['selectedStoreId'],

    syncTickets: null,
    syncTicketsSuccess: ['tickets', 'ticketsOptions', 'kpis', 'ticketKeys'],
    syncTicketsError: null,

    setSelectedTicketId: ['selectedTicketId'],

    syncTicketHistory: null,
    syncTicketHistorySuccess: [
      'ticketHistory',
      'ticket',
      'partnerReturn',
      'samsungFeedback'
    ],
    syncTicketHistoryError: null,

    sendForm: ['formData', 'successCallback'],
    sendFormFinish: null,

    setFilterValues: ['filterValues'],
    setFilterOptions: ['filterOptions'],

    setTicketStatusFilters: ['ticketStatusFilters'],

    resetState: null
  },
  { prefix: 'tickets' }
);

export { Types };
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loadingStores: false,
  indexedStores: [],

  stores: [],

  searchStores: '',
  storesType: 'pending',
  selectedStoreId: null,

  loadingTickets: false,
  ticketsOptions: [],
  tickets: [],
  ticketKeys: {},
  kpis: {},

  selectedTicketId: null,
  loadingTicketHistory: false,
  ticketHistory: [],
  ticketStatus: null,

  ticket: {},
  partnerReturn: {},
  samsungFeedback: '',

  loadingSendForm: false,

  ticketStatusFilters: [],

  filterOptions: [],
  filterValues: {},
  filterKeys: {
    calls_kpis: {
      name: 'Categoria',
      group: 'calls_kpis',
      type: 'checkbox'
    },
    calls_categories: {
      name: 'Item',
      group: 'calls_categories',
      type: 'checkbox'
    },
    partner: { name: 'Parceiro', group: 'general', type: 'checkbox' },
    state: { name: 'Estado', group: 'general', type: 'checkbox' },
    shopping: { name: 'Shopping', group: 'general', type: 'checkbox' },
    city: { name: 'Cidade', group: 'general', type: 'checkbox' }
  }
});

/* Hookup Reducers to Types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNC_STORES]: state => {
    return {
      ...state,
      filterValues: {},
      filterOptions: [],
      loadingStores: true
    };
  },
  [Types.SYNC_STORES_SUCCESS]: (state, { stores }) => {
    return {
      ...state,
      stores,
      loadingStores: false,
      indexedStores: Array.from(Array(stores.length).keys())
    };
  },
  [Types.SYNC_STORES_ERROR]: state => {
    return { ...state, loadingStores: false };
  },

  [Types.SET_STORES_TYPE]: (state, { storesType }) => {
    return { ...state, storesType };
  },

  [Types.SET_SEARCH_STORES]: (state, { searchStores }) => {
    return { ...state, searchStores };
  },
  [Types.SET_INDEXED_STORES]: (state, { indexedStores }) => {
    return { ...state, indexedStores }; // TODO indexed.includes(SelectedStore)?
  },

  [Types.SET_FILTER_OPTIONS]: (state, { filterOptions }) => {
    return { ...state, filterOptions };
  },
  [Types.SET_FILTER_VALUES]: (state, { filterValues }) => {
    return { ...state, filterValues: _.pickBy(filterValues) };
  },

  [Types.SET_SELECTED_STORE_ID]: (state, { selectedStoreId }) => {
    return { ...state, selectedStoreId };
  },

  [Types.SYNC_TICKETS]: state => {
    return {
      ...state,
      loadingTickets: true,
      tickets: [],
      ticketsOptions: [],
      kpis: {},
      ticketHistory: [],
      ticket: {},
      partnerReturn: {},
      ticketStatus: null
    };
  },
  [Types.SYNC_TICKETS_SUCCESS]: (
    state,
    { tickets, ticketsOptions, kpis, ticketKeys }
  ) => {
    return {
      ...state,
      loadingTickets: false,
      tickets,
      ticketsOptions,
      kpis,
      ticketKeys
    };
  },
  [Types.SYNC_TICKETS_ERROR]: state => {
    return { ...state, loadingTickets: false };
  },

  [Types.SET_SELECTED_TICKET_ID]: (state, { selectedTicketId }) => {
    return { ...state, selectedTicketId };
  },

  [Types.SYNC_TICKET_HISTORY]: state => {
    return {
      ...state,
      loadingTicketHistory: true,
      ticketHistory: [],
      ticket: {},
      partnerReturn: {}
    };
  },
  [Types.SYNC_TICKET_HISTORY_SUCCESS]: (
    state,
    { ticketHistory, ticket, partnerReturn, samsungFeedback, ticketStatus }
  ) => {
    return {
      ...state,
      loadingTicketHistory: false,
      ticketHistory,
      ticket,
      partnerReturn,
      samsungFeedback,
      ticketStatus
    };
  },
  [Types.SYNC_TICKET_HISTORY_ERROR]: state => {
    return { ...state, loadingTicketHistory: false };
  },

  [Types.SEND_FORM]: state => {
    return { ...state, loadingSendForm: true };
  },
  [Types.SEND_FORM_FINISH]: state => {
    return { ...state, loadingSendForm: false };
  },

  [Types.SET_TICKET_STATUS_FILTERS]: (state, { ticketStatusFilters }) => ({
    ...state,
    ticketStatusFilters
  }),

  [Types.RESET_STATE]: state => ({ ...INITIAL_STATE })
});

/* Selectors */
export const loadingStores = state => state.TicketsReducer.loadingStores;
export const emptyStores = state => state.TicketsReducer.stores <= 0;
export const indexedStores = state => state.TicketsReducer.indexedStores;
export const getStore = (state, index) => state.TicketsReducer.stores[index];
export const totalStores = state => state.TicketsReducer.indexedStores.length;

export const getType = state => state.TicketsReducer.type;

export const getStores = state => {
  const data = state.TicketsReducer;
  if (data.indexedStores.length === data.stores.length) return data.stores;

  return data.indexedStores.map(index => data.stores[index]);
};

export const loadingTickets = state => state.TicketsReducer.loadingTickets;
export const getSelectedStoreId = state => state.TicketsReducer.selectedStoreId;
export const getTickets = state => state.TicketsReducer.tickets;
export const getTicketsOptions = state => state.TicketsReducer.ticketsOptions;
export const getKpis = state => state.TicketsReducer.kpis;

export const loadingTicketHistory = state =>
  state.TicketsReducer.loadingTicketHistory;
export const getSelectedTicketId = state =>
  state.TicketsReducer.selectedTicketId;
export const getTicketHistory = state => state.TicketsReducer.ticketHistory;

export const getTicket = state => state.TicketsReducer.ticket;
export const getPartnerReturn = state => state.TicketsReducer.partnerReturn;
export const getSamsungFeedback = state => state.TicketsReducer.samsungFeedback;

export const loadingSendForm = state => state.TicketsReducer.loadingSendForm;

export const filterOptions = state => state.TicketsReducer.filterOptions;
export const filterKeys = state => state.TicketsReducer.filterKeys;
export const emptyFilterOptions = state =>
  state.TicketsReducer.filterOptions <= 0;
export const filterValues = state => state.TicketsReducer.filterValues;
export const searchValues = state => state.TicketsReducer.searchStores;

export const getTicketStatusFilters = state =>
  state.TicketsReducer.ticketStatusFilters;
export const getTicketStatus = state => state.TicketsReducer.ticketStatus;
export const getTicketKeys = state => state.TicketsReducer.ticketKeys;
