// import _ from 'lodash';
import React from 'react';

import { Tooltip, message } from 'antd';
import _ from 'lodash';
import { all, put, takeLatest, call, select } from 'redux-saga/effects';

import requestError from '@/helpers/requestError';
import mockCommunications from '@/mocks/communication_notices.json';
import mockStores from '@/mocks/communication_stores.json';
import allRoles from '@/mocks/selectRoles.json';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

import CommunicationsActions, { Types } from './reducer';

export function* syncCommunicationsSagas(action) {
  if (process.env.REACT_APP_MOCK === 'true') {
    yield put(
      CommunicationsActions.syncCommunicationsSuccess(mockCommunications.data)
    );
    return yield call(updateFilterValuesCommunications, action);
  }
  try {
    const { data, status } = yield call(api.get, '/manager/communications');

    if (status === 200) {
      yield put(CommunicationsActions.syncCommunicationsSuccess(data));

      if (data.length > 0) {
        yield call(updateFilterValuesCommunications, action);
      }
    } else {
      yield put(CommunicationsActions.syncCommunicationsError());
    }
  } catch (error) {
    requestError(error);
    yield put(CommunicationsActions.syncCommunicationsError());
  }
}

export function* syncStoresSagas(action) {
  // Mock Stores
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(CommunicationsActions.syncStoresSuccess(mockStores.data));
  }
  try {
    const {
      data: { data },
      status
    } = yield call(api.get, '/stores');

    if (status === 200) {
      yield put(CommunicationsActions.syncStoresSuccess(data));
    } else {
      yield put(CommunicationsActions.syncStoresError());
    }
  } catch (error) {
    requestError(error);
    yield put(CommunicationsActions.syncStoresError());
  }
}

export function* createCommunication(action) {
  const { formData } = action;

  const selectedStoreIds = yield select(
    state => state.CommunicationsReducer.selectedStoreIds
  );
  const selectedUserIds = yield select(
    state => state.CommunicationsReducer.selectedUserIds
  );

  // Mock Send Form
  if (process.env.REACT_APP_MOCK === 'true')
    return yield put(CommunicationsActions.createCommunicationSuccess());
  // return yield put(CommunicationsActions.sendFormFinish());

  try {
    yield call(api.post, `/manager/communications`, {
      ...formData,
      batch_local_ids: selectedStoreIds,
      batch_user_ids: selectedUserIds
    });

    yield put(CommunicationsActions.sendFormFinish());
    yield put(CommunicationsActions.clearData());

    message.success('Comunicado criado com sucesso.');
  } catch (error) {
    requestError(error);
    yield put(CommunicationsActions.sendFormFinish());
  }
}

export function* editCommunication(action) {
  const { formData, communicationId, fileDeletedIds } = action;

  const selectedStoreIds = yield select(
    state => state.CommunicationsReducer.selectedStoreIds
  );
  const selectedUserIds = yield select(
    state => state.CommunicationsReducer.selectedUserIds
  );

  if (!_.isEmpty(fileDeletedIds)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const id of fileDeletedIds) {
      yield put(CommunicationsActions.deleteCommunicationFile(id));
    }
  }

  // Mock Send Form
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(CommunicationsActions.editCommunicationSuccess());
  }

  try {
    yield call(api.put, `/manager/communications/${communicationId}`, {
      ...formData,
      batch_local_ids: selectedStoreIds,
      batch_user_ids: selectedUserIds,
    });

    yield put(CommunicationsActions.sendFormFinish());
    yield put(CommunicationsActions.clearData());

    message.success('Comunicado editado com sucesso.');
  } catch (error) {
    requestError(error);
    yield put(CommunicationsActions.sendFormFinish());
  }
}

export function* deleteCommunication(action) {
  const { id } = action;
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(CommunicationsActions.deleteCommunicationSuccess());
  }

  try {
    yield call(api.delete, `/manager/communications/${id}`);

    yield put(CommunicationsActions.sendFormFinish());
    yield put(CommunicationsActions.clearData());

    message.success('Comunicado excluído com sucesso.');
  } catch (error) {
    requestError(error);
    yield put(CommunicationsActions.sendFormFinish());
  }
}

export function* deleteCommunicationFile(action) {
  const { id } = action;
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(CommunicationsActions.deleteCommunicationSuccess());
  }

  try {
    yield call(api.delete, `/manager/communication_files/${id}`);
  } catch (error) {
    requestError(error);
  }
}

export function* updateCommunicationsInfo() {
  yield put(CommunicationsActions.syncCommunications());
}

export function* filterStores(action) {
  const data = yield select(state => state.CommunicationsReducer);

  const selectedStoresIds = data.selectedStoreIds;

  let stores = Array.from(Array(data.stores.length).keys());

  if (
    !data.searchStores &&
    _.isEmpty(data.filterStoresValues) &&
    data.selectedAllCheckbox === 'all'
  ) {
    return yield put(CommunicationsActions.setIndexedStores(stores));
  }

  if (data.selectedAllCheckbox === 'selected') {
    stores = stores.filter(storeIndexed =>
      selectedStoresIds.includes(data.stores[storeIndexed].id)
    );
  }

  const indexedStores = [];

  stores.forEach(storeIndex => {
    let validate = true;

    const store = data.stores[storeIndex];
    const filterStoresValues = Object.values(data.filterStoresValues);

    if (
      data.searchStores &&
      !store.name.toLowerCase().includes(data.searchStores.toLowerCase())
    )
      validate = false;

    if (!_.isEmpty(data.filterStoresValues)) {
      Object.entries(data.filterStoresValues).forEach(([filter, values]) => {
        if (
          !values.includes(store.store_type) &&
          filterStoresValues.length === 1
        )
          validate = false;
      });
    }

    if (validate) indexedStores.push(storeIndex);
  });

  yield put(CommunicationsActions.setIndexedStores(indexedStores));
}

export function* filterUsers(action) {
  const data = yield select(state => state.CommunicationsReducer);
  const selectedUsersIds = data.selectedUserIds
  let users = Array.from(Array(data.usersByRoles.length).keys());

  if (
    !data.searchUsers &&
    _.isEmpty(data.filterUsersValues) &&
    data.selectedAllCheckbox === 'all' 
  ) {
    return yield put(CommunicationsActions.setIndexedUsers(users))
  }

  if (data.selectedAllCheckbox === 'selected') {
    users = users.filter(userIndexed =>
      selectedUsersIds.includes(data.usersByRoles[userIndexed].id)
    );
  }

  const indexedUsers = []

  users.forEach(userIndex => {
    let validate = true;

    const user = data.usersByRoles[userIndex];
    const filterUsersValues = Object.values(data.filterUsersValues);

    if (
      data.searchUsers &&
      !user.user_name.toLowerCase().includes(data.searchUsers.toLowerCase())
      && !user.role_name.toLowerCase().includes(data.searchUsers.toLowerCase())
    ) {
      validate = false
    }
    if (!_.isEmpty(data.filterUsersValues)) {
      Object.entries(data.filterUsersValues).forEach(([filter, values]) => {
        if (
          !values.includes(user.name) &&
          filterUsersValues.length === 1
        )
        validate = false;
      })
    }

    if (validate) indexedUsers.push(userIndex)
  })

  yield put(CommunicationsActions.setIndexedUsers(indexedUsers))
}

export function* filterCommunications(action) {
  const data = yield select(state => state.CommunicationsReducer);

  const communications = Array.from(Array(data.communications.length).keys());

  if (
    !data.searchCommunications &&
    _.isEmpty(data.filterValues) &&
    _.isEmpty(data.communicationsStatusFilters)
  ) {
    return yield put(
      CommunicationsActions.setIndexedCommunications(communications)
    );
  }

  const indexedCommunications = [];

  communications.forEach(communicationIndex => {
    let validate = true;

    const communication = data.communications[communicationIndex];

    if (
      data.searchCommunications &&
      !communication.title
        .toLowerCase()
        .includes(data.searchCommunications.toLowerCase())
    )
      validate = false;

    if (!_.isEmpty(data.filterValues)) {
      Object.entries(data.filterValues).forEach(([filter, values]) => {
        if (
          data.filterKeys[filter].group === 'general' &&
          !values.includes(communication[filter])
        )
          validate = false;
        else if (
          data.filterKeys[filter].group === 'managers' &&
          _.isEmpty(
            values.filter(value => communication.managers.includes(value))
          )
        )
          validate = false;
        else if (
          data.filterKeys[filter].group === 'selected_roles' &&
          _.isEmpty(
            values.filter(value => {
              const role = _.find(data.allRoles, { name: value })
              return communication.selectedroles.includes(role.id)
            })
          )
        )
          validate = false;
        else if (
          data.filterKeys[filter].group !== 'general' &&
          data.filterKeys[filter].group !== 'managers' &&
          data.filterKeys[filter].group !== 'selected_roles' &&
          !values.includes(communication[data.filterKeys[filter].group][filter])
        )
          validate = false;
      });
    }

    if (
      !_.isEmpty(data.communicationsStatusFilters) &&
      _.isEmpty(
        data.communicationsStatusFilters.filter(
          status => communication?.enabled === status
        )
      )
    ) {
      validate = false;
    }

    if (validate) indexedCommunications.push(communicationIndex);
  });

  yield put(
    CommunicationsActions.setIndexedCommunications(indexedCommunications)
  );

  const communicationsIds = indexedCommunications.map(
    indexed => data.communications[indexed].id
  );

  if (
    data.selectedCommunicationId &&
    !communicationsIds.includes(data.selectedCommunicationId)
  ) {
    yield put(CommunicationsActions.clearData());
  }
}

export function* updateFilterValuesCommunications(action) {
  const data = yield select(state => state.CommunicationsReducer);

  const communications = [];

  data.indexedCommunications.forEach(communicationIndex => {
    communications.push(data.communications[communicationIndex]);
  });

  const compare = (a, b) => {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  };

  const tooltipComponent = (text, count) => {
    if (text.length <= 11) return `${text} (${count})`;

    return (
      <Tooltip title={text} placement="right">
        {`${text.slice(0, 11)}... (${count})`}
      </Tooltip>
    );
  };

  const filters = Object.entries(data.filterKeys).map(
    ([key, filter], index) => {
      let countKey = {};
      let managers = [];
      let allroles = [];

      if (filter.group === 'general') {
        countKey = _.countBy(communications, key);
      } else if (filter.group === 'managers') {
        communications.forEach(communication => {
          managers = managers.concat(communication.managers);
        });
        countKey = _.countBy(managers);
      } else if (filter.group === 'selected_roles') {
        communications.forEach(communication => {
          if (!communication.selectedroles.includes(null)) {
            data.allRoles.forEach(role => {
              if (communication.selectedroles.includes(role.id)) {
                allroles = allroles.concat(role.name);
              }
            })
          }
        })
        countKey = _.countBy(allroles);
      } else {
        countKey = _.countBy(
          communications,
          communication => communication[filter.group][key]
        );
      }

      if (Object.keys(countKey).filter(i => i !== 'undefined') <= 0)
        return null;

      const currentFilters = Object.keys(data.filterValues);

      if (
        currentFilters.length > 0 &&
        currentFilters.includes(key) &&
        data.filterOptions[index]
      ) {
        const { items } = data.filterOptions[index];
        Object.keys(items).forEach(value => {
          if (!(value in countKey)) countKey[value] = items[value];
        });
      }

      return {
        key,
        items: countKey,
        name: filter.name,
        type: filter.type,
        options: _.compact(Object.keys(countKey))
          .map(value => ({
            label: tooltipComponent(value, countKey[value]),
            value
          }))
          .sort(compare)
      };
    }
  );
  yield put(CommunicationsActions.setFilterOptions(filters));
}

export function* setRoles(action) {
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(CommunicationsActions.setAllRoles(allRoles));
  }
  try {
    const { data } = yield call(api.get, 'manager/roles');
    yield put(CommunicationsActions.setAllRoles(data));
  } catch (error) {
    yield put(CommunicationsActions.setAllRoles([]));
  }
}

export function* syncUsersByRoles(action) {
  const { 
    selectedRoles,
    selectedUserIds
  } = yield select(state => state.CommunicationsReducer)

  try {
    if (!_.isEmpty(selectedRoles)) {
      const { data } = yield call(api.get, 'manager/users', {
        params: {
          roles: selectedRoles
        }
      });

      const users = []
      selectedUserIds.forEach(userId => {
        data.filter(user => user.id === userId && users.push(user.id))
      })

      yield put(CommunicationsActions.setCommunicationUserIds(users))
      yield put(CommunicationsActions.setUsersByRoles(data));
      yield call(filterUsers)
    }
  } catch (e) {
    yield put(CommunicationsActions.setUsersByRoles([]));
  };
};

export default function*() {
  yield all([
    takeLatest(Types.SYNC_COMMUNICATIONS, syncCommunicationsSagas),
    takeLatest(Types.SYNC_STORES, syncStoresSagas),
    takeLatest(
      Types.SET_INDEXED_COMMUNICATIONS,
      updateFilterValuesCommunications
    ),
    takeLatest(Types.CREATE_COMMUNICATION, createCommunication),
    takeLatest(Types.EDIT_COMMUNICATION, editCommunication),
    takeLatest(Types.DELETE_COMMUNICATION, deleteCommunication),
    takeLatest(Types.DELETE_COMMUNICATION_FILE, deleteCommunicationFile),
    takeLatest(Types.SET_SEARCH_STORES, filterStores),
    takeLatest(Types.SET_SEARCH_USERS, filterUsers),
    takeLatest(Types.SET_FILTER_VALUES, filterCommunications),
    takeLatest(Types.SET_FILTER_STORES_VALUES, filterStores),
    takeLatest(Types.SEND_FORM_FINISH, updateCommunicationsInfo),
    takeLatest(Types.SET_SEARCH_COMMUNICATIONS, filterCommunications),
    takeLatest(Types.SET_SELECTED_ALL_CHECKBOX, filterStores),
    takeLatest(Types.SET_SELECTED_ALL_CHECKBOX, filterUsers),
    takeLatest(Types.CLEAR_DATA, filterStores, filterUsers),
    takeLatest(Types.SYNC_ALL_ROLES, setRoles),
    takeLatest(Types.SYNC_USERS_BY_ROLES, syncUsersByRoles),
  ]);
}
