import _ from 'lodash';

const validationTypes = (item, values = null, type) => {
  if (!values || !values.length) return true;
  switch (type) {
    case 'checkbox':
      return values.includes(item);
    case 'range':
      return item >= _.first(values) && item <= _.last(values);
    case 'radio':
      return values === item;
    case 'checkbox_inclusion':
      return item.includes(values) || values.includes(item);
    default:
      return values.includes(item);
  }
};

export default ({ content = [], filterKeys, filterValues, injection = null }) => {
  const items = Array.from(Array(content.length).keys());
  const indexedItems = [];

  _.forEach(items, itemIndex => {
    let validate = true;

    const current = content[items[itemIndex]];
    const injected = injection ? injection(current) : {};
    const item = {...current,  ...injected};

    if (!_.isEmpty(filterValues)) {
      Object.entries(filterValues).forEach(([filter, { values }]) => {
        const { group, type } = filterKeys[filter];

        if (group === 'general' && validate) {
          const validationItem = item[filter];
          validate = validationTypes(validationItem, values, type);
        } else if (group !== 'general' && validate) {
          const validationItem = item[group][filter];
          validate = validationTypes(validationItem, values, type);
        }
      });
    }

    if (validate) indexedItems.push(itemIndex);
  });

  return indexedItems;
};

// SECTION Filtering content

const innerOptions = (items = [], key, filterKeys) => {
  const { group = 'general' } = filterKeys[key];
  const options = _.chain(items)
    .reduce((prev, item) => {
      let value = null;

      if(group === 'general') {
        value = item[key] || null
      }
      if(group && item[group]) {
        value = item[group][key] || null
      }

      if(value) {
        return [...prev, value]
      }
      return prev
    },[])
    .uniq()
    .value();
  if (options && !_.isEmpty(options)) return options;

  return [];
};

const innerAvailable = (items, indexed, key, filterKeys) => {
  const filterItem = filterKeys[key];
  const { group = 'general' } = filterItem;

  const available = _.chain(indexed)
    .reduce((prev, curr) => {
      let value = null;
      const item = items[curr] || null;
      if(item) {
        if(group === 'general'){
          value = item[key] || null;
        }
        if(group && item[group]) {
          value = item[group][key] || null;
        }
      }
      if(value) {
        return [...prev, value];
      }
      return prev
    },[])
    .uniq()
    .value();

  return available;
};

export const getFilterOptions = (items = [], indexedItems = [], filterKeys) => {
  if (items && !_.isEmpty(items)) {
    const keys = _.keys(filterKeys);

    const options = _.chain(keys)
      .map(key => ({
        key,
        options: innerOptions(items, key, filterKeys),
        available: innerAvailable(items, indexedItems, key, filterKeys)
      }))
      .keyBy('key')
      .value();
    if (options && !_.isEmpty(options)) {
      return options;
    }
  }
  return {};
};
