import { put, call, all, takeLatest } from 'redux-saga/effects';

import globalScoreMock from '@/mocks/global_score.json';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';

import Actions, { Types } from './reducer';

function* syncScoreSaga(action) {
  try {
    const { local } = action;

    if (process.env.REACT_APP_MOCK === 'true') {

        const {score, values} = globalScoreMock;
        
        return yield put(Actions.syncScoreSuccess(score, values, local));
    }

    if (local) {
      const {
        data: { total_score: localScore, kpi: localKpi },
        status
      } = yield call(api.get, '/ranking/general_scores');
      if (status === 200) {
        return yield put(Actions.syncScoreSuccess(localScore, localKpi, local));
      }
      return yield put(Actions.syncScoreError());
    }
  } catch (error) {
    return yield put(Actions.syncScoreError());
  }
}

export default function*() {
  return yield all([takeLatest(Types.SYNC_SCORE, syncScoreSaga)]);
}
