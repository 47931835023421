import React, { Suspense, memo } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { isLoggedIn } from '@/auth/redux/reducer';
import Loader from '@/components/Loader';
import { BlankLayout, AppLayout } from '@/layouts';
import ErrorBoundary from '@/routes/ErrorBoundary';

import { allFlattenRoutes as routes } from './index';

const Page404 = React.lazy(() => import('@/pages/404'));

const Routes = memo(props => {
  const LoggedIn = useSelector(state => isLoggedIn(state));
  const Layout = LoggedIn ? AppLayout : BlankLayout;

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Layout {...props}>
            <Switch>
              {routes.map((route) => {
                return (
                  <route.route
                    key={route.key}
                    path={route.path}
                    roles={route.roles}
                    exact={route.exact}
                    restricted={route.restricted}
                    component={route.component}
                  />
                );
              })}
              <Route render={data => (<Page404 {...data} />)} />
            </Switch>
          </Layout>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
  );
});

export default Routes;
