import { createActions, createReducer } from 'reduxsauce';

/* Types & Creators */
const { Types, Creators } = createActions(
  {
    /* index */
    clearMessages: null,
    togglePolling: null,

    fetchChatRooms: ['callId'],
    fetchChatRoomsSuccess: ['rooms'],
    fetchChatRoomsError: null,

    fetchMessages: null,
    fetchMessagesSuccess: ['messages'],
    fetchMessagesError: null,

    createMessage: ['contentMessage'],
    createMessageSuccess: ['messages'],
    createMessageError: null,

    setRoomId: ['roomId'],
  },
  { prefix: 'MaintenanceChat/' }
);

export { Types };
export default Creators;

/* Initial State */
const INITIAL_STATE = {
  messages: [],
  rooms: [],
  contentMessage: null,
  callId: null,
  roomId: null,
  polling: false,
  updatingRead: false,
  loadingMessages: false,
  loadingChatRooms: true,
  loadingCreatingMessage: false,
};

/* Reducers */
export const reducer = createReducer(INITIAL_STATE, {
  /* index */
  [Types.CLEAR_MESSAGES]: (state) => ({
    ...state,
    messages: [],
  }),

  [Types.TOGGLE_POLLING]: (state) => ({ ...state, polling: !state.polling }),

  [Types.FETCH_CHAT_ROOMS]: (state, { callId }) => ({
    ...state,
    callId,
    loadingChatRooms: true,
  }),
  [Types.FETCH_CHAT_ROOMS_SUCCESS]: (state, { rooms }) => ({
    ...state,
    rooms,
    loadingChatRooms: false,
  }),
  [Types.FETCH_CHAT_ROOMS_ERROR]: (state) => ({
    ...state,
    loadingChatRooms: false,
  }),

  [Types.FETCH_MESSAGES]: (state) => ({ ...state, loadingMessages: true }),
  [Types.FETCH_MESSAGES_SUCCESS]: (state, { messages }) => ({
    ...state,
    messages,
    loadingMessages: false,
  }),
  [Types.FETCH_MESSAGES_ERROR]: (state) => ({
    ...state,
    loadingMessages: false,
  }),

  [Types.CREATE_MESSAGE]: (state, { contentMessage }) => ({
    ...state,
    contentMessage,
    loadingCreatingMessage: true,
  }),
  [Types.CREATE_MESSAGE_SUCCESS]: (state, { messages }) => ({
    ...state,
    messages,
    loadingCreatingMessage: false,
  }),
  [Types.CREATE_MESSAGE_ERROR]: (state) => ({
    ...state,
    loadingCreatingMessage: false,
  }),

  [Types.SET_ROOM_ID]: (state, { roomId }) => ({
    ...state,
    roomId,
  }),
});
