import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { isLoggedIn } from '@/auth/redux/reducer';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const LoggedIn = useSelector(state => isLoggedIn(state));

  return (
    <Route
      {...rest}
      render={props => {

        if (LoggedIn && restricted) {
          return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
        }

        return <Component {...props} />;
      }}
    />
)};

export default PublicRoute;
