import antdBr from 'antd/lib/locale-provider/pt_BR';

import page404 from './page404';
import page500 from './page500';

const brLang = {
  messages: {
    ...page500,
    ...page404,
  },
  antd: antdBr,
  locale: 'pt-BR',
};

export default brLang;
