import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
	{
		fetchItems: ['endpoint'],
		fetchItemsSuccess: ['items'],
		fetchItemsError: null,

		fetchCategories: ['endpoint'],
		fetchCategoriesSuccess: ['categories'],
		fetchCategoriesError: null,

		fetchTypesDamages: ['endpoint'],
		fetchTypesDamagesSuccess: ['typesDamages'],
		fetchTypesDamagesError: null,

		updateFilterKeys: ['filterKeys'],
		updateFilterValues: ['filterValues'],
		updateFilterOptions: ['filterOptions'],
		updateIndexedItems: ['indexedItems'],

		createItem: ['endpoint', 'formData'],
		createItemSuccess: null,
		createItemError: null,

		editItem: ['endpoint', 'id', 'formData'],
		editItemSuccess: null,
		editItemError: null,

		desativeItem: ['endpoint', 'id'],
		desativeItemSuccess: null,
		desativeItemError: null,

		setSelectedItem: ['data'],
		setShowFormModal: ['data'],
		setShowStatusItemModal: ['data'],

		setSearchValue: ['searchValue'],
		resetReducer: null,
	},
	{ prefix: 'config_maintenance/' }
);

export { Types }
export default Creators

export const INITIAL_STATE = Immutable({
	items: [],
	indexedItems: [],
	categories: [],
	typesDamages: [],
	filterValues: [],
	filterOptions: {},

	fetchedCategories: false,
	fetchedTypesDamages: false,

	loadingItems: false,
	loadingCategories: false,
	loadingTypesDamages: false,
	loadingCreateItem: false,
	loadingEditItem: false,
	loadingDesativeItem: false,

	selectedItem: {},
	showFormModal: false,
	showStatusItemModal: false,

	searchValue: '',

	filterKeys: {
		operation: {
			name: 'Tipo',
			type: 'checkbox',
			group: 'general'
		},
		category: {
			name: 'Categoria',
			type: 'checkbox',
			group: 'general'
		}
	}
})

export const reducer = createReducer(INITIAL_STATE, {
	[Types.FETCH_ITEMS]: state => ({
		...state,
		loadingItems: true
	}),
	[Types.FETCH_ITEMS_SUCCESS]: (state, { items }) => ({
		...state,
		items,
		loadingItems: false
	}),
	[Types.FETCH_ITEMS_ERROR]: (state) => ({
		...state,
		loadingItems: false
	}),
	[Types.FETCH_CATEGORIES]: state => ({
		...state,
		loadingCategories: true
	}),
	[Types.FETCH_CATEGORIES_SUCCESS]: (state, { categories }) => ({
		...state,
		categories,
		loadingCategories: false,
		fetchedCategories: true
	}),
	[Types.FETCH_CATEGORIES_ERROR]: (state) => ({
		...state,
		loadingCategories: false
	}),
	[Types.FETCH_TYPES_DAMAGES]: state => ({
		...state,
		loadingTypesDamages: true
	}),
	[Types.FETCH_TYPES_DAMAGES_SUCCESS]: (state, { typesDamages }) => ({
		...state,
		typesDamages,
		loadingTypesDamages: false,
		fetchedTypesDamages: true
	}),
	[Types.FETCH_TYPES_DAMAGES_ERROR]: (state) => ({
		...state,
		loadingTypesDamages: false
	}),
	[Types.UPDATE_FILTER_VALUES]: (state, { filterValues }) => ({
		...state,
		filterValues
	}),
	[Types.UPDATE_FILTER_OPTIONS]: (state, { filterOptions }) => ({
		...state,
		filterOptions
	}),
	[Types.UPDATE_INDEXED_ITEMS]: (state, { indexedItems }) => ({
		...state,
		indexedItems
	}),
	[Types.CREATE_ITEM]: (state) => ({
		...state,
		loadingCreateItem: true
	}),
	[Types.CREATE_ITEM_SUCCESS]: state => ({
		...state,
		loadingCreateItem: false
	}),
	[Types.CREATE_ITEM_ERROR]: state => ({
		...state,
		loadingCreateItem: false
	}),
	[Types.EDIT_ITEM]: state => ({
		...state,
		loadingEditItem: true
	}),
	[Types.EDIT_ITEM_SUCCESS]: state => ({
		...state,
		loadingEditItem: false
	}),
	[Types.EDIT_ITEM_ERROR]: state => ({
		...state,
		loadingEditItem: false
	}),
	[Types.DESATIVE_ITEM]: state => ({
		...state,
		loadingDesativeItem: true
	}),
	[Types.DESATIVE_ITEM_SUCCESS]: state => ({
		...state,
		loadingDesativeItem: false
	}),
	[Types.DESATIVE_ITEM_ERROR]: state => ({
		...state,
		loadingDesativeItem: false
	}),
	[Types.SET_SELECTED_ITEM]: (state, { data }) => ({
		...state,
		selectedItem: data
	}),
	[Types.SET_SHOW_FORM_MODAL]: (state, { data }) => ({
		...state,
		showFormModal: data
	}),
	[Types.SET_SHOW_STATUS_ITEM_MODAL]: (state, { data }) => ({
		...state,
		showStatusItemModal: data
	}),
	[Types.SET_SEARCH_VALUE]: (state, { searchValue }) => ({
		...state,
		searchValue
	}),
	[Types.RESET_REDUCER]: state => ({ ...INITIAL_STATE })
})

export const PATH = 'MaintenanceConfigurationReducer';

const root = selector => {
	return state => state[PATH][selector];
};

export const items = {
	getItems: root('items'),
	getIndexedItems: root('indexedItems'),
	getSelectedItem: root('selectedItem'),

	getShowFormModal: root('showFormModal'),
	getShowStatusItemModal: root('showStatusItemModal'),

	getLoadingItems: root('loadingItems'),
	getLoadingCreateItem: root('loadingCreateItem'),
	getLoadingEditItem: root('loadingEditItem'),
	getLoadingDesativeItem: root('loadingDesativeItem')
};

export const search = {
	getSearchValue: root('searchValue')
}

export const categories = {
	getCategories: root('categories'),
	getLoadingCategories: root('loadingCategories'),
	getFetchedCategories: root('fetchedCategories')
};

export const typesDamages = {
	getTypesDamages: root('typesDamages'),
	getLoadingTypesDamages: root('loadingTypesDamages'),
	getFetchedTypesDamages: root('fetchedTypesDamages')
}

export const filter = {
	getFilterValues: root('filterValues'),
	getFilterOptions: root('filterOptions'),
	getFilterKeys: root('filterKeys')
}