import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  sync: null,

  syncData: null,
  syncDataSuccess: ['franchises', 'franchisesKeys', 'locations', 'locationsKeys'],
  syncDataError: null,

  // syncLocations: null,
  // syncLocationsSuccess: ['locations', 'locationsKeys'],
  // syncLocationsError: null,

  syncItems: null,
  syncItemsSuccess: ['items', 'itemsKeys'],
  syncItemsError: null,

  setIndexedItems: ['indexedItems'],

  updateFilterValues: ['filterValues'],
  updateFilterOptions: ['filterOptions'],

  resetData: null,
}, {prefix:'kpi_dashboard'});

export {Types};
export default Creators;

const INITIAL_STATE = Immutable({
  // SECTION Franchise
  franchises: [],
  franchisesKeys: {},
  loadingData: false,
  // SECTION Location
  locations: [],
  locationsKeys: {},
  // SECTION Items
  items: [],
  itemsKeys: {},
  indexedItems: [],

  // SECTION Filter
  filterValues: {},
  filterOptions: {},
  // NOTE filterKeys ja esta setado no constants

  // SECTION Main
  loading: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNC]: state => state,
  // SECTION syncFranchises
  [Types.SYNC_DATA]: state => ({ ...state, loadingData: true }),
  [Types.SYNC_DATA_SUCCESS]: (state, { franchises, franchisesKeys, locations, locationsKeys }) => ({
    ...state,
    franchises,
    franchisesKeys,
    locations,
    locationsKeys,
    loadingData: false
  }),

  [Types.SYNC_DATA_ERROR]: state => ({ ...state, loadingData: false }),

  // SECTION syncLocations
  // [Types.SYNC_LOCATIONS]: state => ({ ...state, loadingLocations: true }),
  // [Types.SYNC_LOCATIONS_SUCCESS]: (state, { locations, locationsKeys }) => ({
  //   ...state,
  //   locations,
  //   locationsKeys,
  //   loadingLocations: false
  // }),
  // [Types.SYNC_LOCATIONS_ERROR]: state => ({ ...state, loadingLocations: false }),

  // SECTION syncItems
  [Types.SYNC_ITEMS]: state => ({...state, loading: true}),
  [Types.SYNC_ITEMS_SUCCESS]: (state, {items, itemsKeys}) => ({
    ...state,
    items,
    itemsKeys,
    loading: false,
  }),
  [Types.SYNC_ITEMS_ERROR]: state => ({...state, loading: false}),

  // SECTION setIndexedLocations
  [Types.SET_INDEXED_ITEMS]: (state, { indexedItems }) => ({
    ...state,
    indexedItems
  }),

  // SECTION updateFilterValues
  [Types.UPDATE_FILTER_VALUES]: (state, { filterValues }) => ({
    ...state,
    filterValues
  }),

  // SECTION updateFilterValues
  [Types.UPDATE_FILTER_OPTIONS]: (state, { filterOptions }) => ({
    ...state,
    filterOptions,
  }),
  [Types.RESET_DATA]: () => ({...INITIAL_STATE}),
});

const path = 'KpisDashboardReducer';

export const getAll = state => state[path];

export const getFranchises = {
  franchises: state => state[path].franchises,
  keys: state => state[path].franchisesKeys,
  loading: state => state[path].loadingData,
};

export const getLocations = {
  locations: state => state[path].locations,
  keys: state => state[path].locationsKeys,
  loading: state => state[path].loadingData,
};

export const getItems = {
  items: state => state[path].items,
  keys: state => state[path].itemsKeys,
  indexed: state => state[path].indexedItems
}

export const getFilter = {
  values: state => state[path].filterValues,
  options: state => state[path].filterOptions,
};

export const getMain = {
  loading: state => state[path].loading,
};

